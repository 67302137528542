/**** Responsive ****/

@media (min-width: 1200px){        
	.mobile_hmenu_list{ display: none;}/* Mobile cms menu */
	.hmenu_list > li:hover > a > .hdd_arrow{ opacity: 1; visibility: visible;}
	.hcategory_sec { display: none; }
	.cartaction_checkout{margin: 0px 20px; }
}

@media (min-width: 1080px){
	.container{ max-width: 100%; width: 1100px!important; }
}

@media (min-width: 576px){    
	.modal-dialog { margin: 1.75rem auto; }
	.modal-dialog-centered { min-height: calc(100% - (1.75rem * 2)); min-height: -webkit-calc(100% - (1.75rem * 2));}
}

@media (max-width: 1280px){
	ul.hmenu_list > li > a, .hsign_sec a{font-size: 13px;}
	.merchant-outlet .htico_sign span{ font-size: 12px; }
	.merchant-outlet{ top: 5px;}
	.hdropdown-list .hmenu-login-act ul.submenu_list li a{ font-size: 13px; } 
	ul.hmenu_list > li > a{margin: 0 2px}    
	.hordertype_sec a { padding: 12px 25px 12px;  margin: 0 15px 0 20px;font-size: 14px;}    
	.hordertype_sec a:after { right: 10px; top: 10px; font-size: 18px;}    
	.htico_cart{margin-left: 10px}
}

@media (max-width: 1199px){
	.hcategory_menulist li{ border-bottom: 0.5px solid rgba(13,11,6, 0.17); }
	.hcategory_menu.open { opacity: 1;visibility: visible;z-index: 91; }    
	.hcategory_sec a{ color: #000; }    
	.hcategory_sec{ background: #fff; width: 59%; height: 48px;   border-radius:4px; border: 1px solid #e4e4e4;}  
	input{ -webkit-appearance: none; -moz-appearance: none; appearance: none;  border-radius:0;}
	.sticky .alert_fixed{ top: 95px;}
	
	.hcategory_selected_text {
		padding: 12px 50px 12px 12px;
		color: #000;
		font-size: 16px;
		text-transform: capitalize;
		display: block;
		position: relative;
		font-weight: 500;
	}
	.hcategory_trigger {
		position: absolute;
		right: 15px;
		top: 12px;
		-webkit-transition: all .4s;
		transition: all .4s;
		z-index: 1;
		font-size: 18px;
		padding-right: 30px;
	}
	.hcategory_trigger:after {
		position: absolute;
		background: url(../images/down-img.png) no-repeat;
		background-size: contain;
		width: 22px;
		height: 20px;
		content: "";
		right: 0;
		top: 8px;
	}
	.hcategory_menu {
		background: #f4f4f4;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		max-height: 400px;
		opacity: 0;
		visibility: hidden;
		-webkit-transition: all .4s;
		transition: all .4s;
		overflow: auto;
	}
	.hcategory_menulist {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.hcategory_menulist>li>a {
		background: #f8f8f8;
		color: #000;
		font-size: 18px;
		padding: 12px 45px 12px 15px;
		display: block;
		text-transform: uppercase;
		font-weight: 600;
	}
	.hcategory_submenu {
		list-style: none;
		padding: 0;
		margin: 0;
	}
	.hcategory_submenu>li>a {
		color: #000;
		font-size: 18px;
		padding: 12px 15px 12px 35px;
		display: block;
	}

	.hservingtype a{ font-size: 14px; }
}

@media (max-width: 1080px){
	.header_bot{display: block; margin: 15px 0 0 0;}       
	.f-address-img{display: none}         
    .hcart_like, .htico_cart{  margin-left: 5px; }
	.mobile-login ul{right: 0; left: inherit;}   
	.mobile-login {position: absolute; right: 70px;}

	.footer-top{flex-wrap: wrap}    
	.footer-newletter { width: 47%; order: 2;}
	.footer-nav { order: 3; width: 47%;}
	.footer-logo { width: 100%; order: 1;margin-bottom: 20px; margin-top: 60px;}
	.mobile-order-now{display: flex; box-shadow: 0 0 13px 0 rgb(0 0 0 / 11%);}    
	.social-media { margin: 30px 0 20px 0; padding-left: 0;}  
	.sticky-menu-nav { top: 150px;} 

	.outlet_list ul{justify-content: flex-start;}
	.outlet_list li{margin: 0 10px 10px 0;}
	.outlet_list li:last-child{margin-right: 0;}

	.myacc_lhs_singletier, .myacc_rhs_singletier{ width: 50%;}
	.myacc_rhs_singletier p, .myacc_rhs_multitier p{text-align: right;}

	.merchant-footer-top .footer-newletter{ width: 45%; }
}

@media (max-width: 991px){
	.hservingtype a{ padding: 8px 32px;}	
	.hmenu_list > li{margin-right: 5px} 

	.homebanner-content{ width: 55%; } 
	.myacc_product_li{width: 32%;margin: 0 2% 20px 0;}
	
	.myacc_product_li:nth-child(4n){ margin-right: 20px; }
	.myacc_product_li:nth-child(3n){ margin-right: 0; }
	.home-banner{height: 400px;}
	.inner_img_lhs li h5, .used_voucher .inner_img_lhs li h4, .mobile_voucher_uddiv .inner_img_lhs li h4, .voucher-maindiv .inner_img_lhs li h4{
		font-size: 20px;
	}
	.about-button li a{padding: 10px;}
	.about-button li img{margin-right: 5px;}
	.about_info_lhs{min-width: auto;}
	.login-rhs{ padding: 20px; }
	.customer_Ndashboard .welcome-title h1, .myacc_info_rhs_single .welcome-title h1, .myacc_info_rhs_multi .welcome-title h1{
		font-size: 24px;
	}
	.account_sec{flex-wrap: wrap;}
	.accmenu_sec { width: 100%;}
	.accprofile { padding: 20px 10px;}
	.setting_menu_list { display: none;}
	.accsec_right { width: 100%; padding: 20px 15px;}
	.other-add-row li { width: 100%;}
	.other-add-row li:nth-of-type(3n+3) { margin-right: 1.66666%;}
	.acc-inform .row{ margin: 0; }
	.acc-inform .date-inner{ padding: 0;}
	.acc-inform .gender-inner{ padding: 0;}
	.acc-inform .unitno_one { padding: 0 5px 0 0px;}
	.acc-inform .unitno_two { padding: 0px 0px 0 5px;}

	.merchent-reward-lhs { padding: 0 30px 0 30px;}
	.merchent-reward-rhs{padding: 0 30px;}

	.promotion_content_main .promotion-list-ul, .promotion_voacher_ul {
	    justify-content: space-between;
	}

	.hservingtype{
		display: none;
	}
}

@media (max-width: 880px){
	.hmenu_sec{display: none;}
    .header-top-cls{padding: 10px 0;}
	.logo { position: absolute; left: 0; right: 0; margin: auto;} 
	.merchant_logo {
		position: absolute;
		left: 0;
		right: 0;
		margin: auto;
		max-width: 120px;
	}

	.customer-header .logo-main-section {
		height: 68px;
	}

	.logo-main-section {height: 100px;	}
	.menu_icon .icon-bar {display: block;background-color: #000;height: 2px;transition: all 400ms;}
	.menu_icon .icon-bar+.icon-bar {margin-top: 6px;}
	.sticky-menu-nav { top: 126px;}
	.menu_icon.trigger_menunav_act{ display: block; width: 30px; height: 19px; margin: 3px 0px;padding:0px; cursor: pointer;}

	.about_desscribtion ul{justify-content: flex-start;}
	.about_desscribtion li{margin: 0 15px 10px 0;	}
	.about-button li a{font-size: 14px;}

	.singletier_menumain li { width: 22%;}

	.hsign_sec{display: none}

	.merchant-memberinner li{
		flex-flow: column;
		justify-content: center;
		padding: 15px 10px;
	}

	.merchant-left-imgdiv{
		margin-bottom: 10px;
	}

	.merchant-left-contentdiv{
		text-align: center;
		margin: 0;
	}

	.desktop-login-list{
		display: none;
	}
}

@media (max-width: 780px){
	h1{ font-size: 35px}
	h2{ font-size: 30px}    
	h3{ font-size: 26px;}        
	h4{ font-size: 21px;}   
   
	.hmenu-login-section {position: static;top: inherit; transform: none;}   
	.mobile-login {left: 50px; top: 19px;right: inherit;}    
	.mobile-login ul { left: 0; right: inherit;}   
	.header-top-cls {padding: 5px 0;}
	.customer-header .logo-main-section {height: 62px;}    
	.logo { max-width: 62px;}
	.merchant_logo{ max-width: 100px;}
	.footer-logo_div img{height: 80px;}
	.footer-logo{ margin-top: 30px; }
	.copyright-section { padding: 10px 10px;}    
	.copyright-section p { font-size: 13px;}      
	.sticky-menu-nav { top: 122px;}

	.myacc_details{ display: none;}
	.myacc_Dmain{ margin-top: 20px;}
	.myacc_info, .about_info{background: transparent;border-radius: 0;border: none;box-shadow: none;flex-flow: column;}
	.myacc_info_lhs{max-width: 100%;background: #fff;border-radius: 20px;box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
		padding: 20px 20px; margin-bottom: 15px;
	}

	.customer-welcome{
		margin-bottom: 15px;
	}

	.myacc_linkdesc{display: none;}
	.welcome-title { display: flex; justify-content: space-between; align-items: center; width: 100%;}
	.myacc_linkpara { margin: 0; width: 80px; display: flex; justify-content: center;}

	.welcome-title h1{width: calc(100% - 80px);font-size: 22px;margin: 0; line-height: 1.5;}
	.welcome-title h1 img{margin-left: 10px;}/*.welcome-title h1 div{display: flex;align-items: center;}*/
	.welcome-title span{margin-right: 7px;}
	.myacc_info_rhs{max-width: 100%;border-radius: 20px 20px 0px 0;padding: 30px 20px;}

	.destop_acc_dbutton{display: none;}
	.mobile_acc_dbutton,.mobile-promotion{display: block;}
	.promotions_info_mem .destop_acc_dbutton{display: none;}
	.social-media { margin: 10px 0 20px 0;}
	.homebanner-content h3{font-size: 26px;}
	.homebanner-content h1{font-size: 30px;}
	.home-banner .slick-slide img{object-position: center;}

	.customrt_product_main .myacc_product_li { width: 49%; margin: 0 0 20px;}
	.customrt_product_main ul{justify-content: space-between;}

	.destop_myacc_Dmain, .promotion_content_main .myacc_locat_div, .rward-none{display: none;}
	.mobile_myacc_Dmain, .aboutmobile_info_lhs, .aboutmobile_info_rhs{display: block;}

	.mobile_myacc_Dmain { margin-top: 0;}
	.outlet_list_inner{width: 100%;}
	.outlet_main{padding: 0px 0 20px;}
	.outlet_main .myacc_locat_div h4{font-size: 17px;}

	.merchent-mobile_dbord, .merchent-mobile_rsh .merchent_info_ul, .merchent-mobile_rsh .member-prog-bar,
	.merchent-mobile_rsh .merchebt_Rpoint .merchebt_Rpoint_para{
	    display: none;
	}
	.merchent-mobile_dbord .welcome-title p{display: none;}

	.myacc_main_div .merchent_dbord{
		background-position: left;
		background-image: url(../banner/merchent_mobiledash.jpg);
		flex-flow: row wrap;
	}

	.merchebt_Rpoint li { width: 120px; border-radius: 50px 0px 0 50px;}
	.merchent-mobile_rsh{padding: 20px 0 20px 20px;}
	.merchent-mobile_rsh .Rpoint_list{margin: 0;}
	.merchebt_Rpointul h2{font-size: 20px;}
	.merchent-mobile_dbordblock{ display: flex; max-width: 60%; min-width: auto;}
	.merchent-mobile_dbord p{display: none;}
	.merchent-mobile_rsh{max-width: 40%;min-width: auto;}
	.merchent-mobile_dbordblock {display: flex;justify-content: center;}
	.merchent-mobile_dbordblock .welcome-title { display: block;}
	.merchent-mobile_outlet, .merchant-voacher-mobile, .merchant-voucher-tab{ display: block;}
	.merchant-vistmain{ display: flex; flex-wrap: wrap; justify-content: space-between; align-items: center;}
	.merchant_destopdash_main, .merchant-voacher-destop, .multitier .myacc_info_lhs, .about_info{display: none;}
	.merchant_mobiledash_main .merchant_mobiledash_uldiv{justify-content: space-between;}
	.merchant_mobiledash_main .myacc_product_li{width: 49%; margin: 0 0 20px;}
	.merchant_dash_contain{margin-top: 0;}
	.merchent-reward-lhs {padding: 0 20px 0 0px;}
	.merchent-reward-rhs{padding: 0 0  0 20px;}

	.myacc_info_rhs_multi{
		background-image: url(../banner/myacc_multibg.jpg);
    	background-repeat: no-repeat;
    	background-size: cover;
    	background-position: center;
	}

	.customer_Ndashboard .myacc_info_lhs {align-items: flex-start; }
    .myacc_main_div .customer_Ndashboard{border-radius: 20px; }
    .customer_Ndashboard .myacc_linkpara{justify-content: flex-start;}
    .singletier .myacc_info_lhs{display: none;}

    .myacc_lhs_singletier .welcome-title{flex-flow: column;justify-content: flex-start;align-items: flex-start;}
    .myacc_lhs_singletier .myacc_linkpara, .multitier_button_rsh .myacc_linkpara{justify-content: flex-start;}
    .myacc_info_rhs_single .welcome-title h1 { font-size: 20px; width: 100%;}

	.aboutbk_multitier, .about_menublock, .merchent_serving{ display: block;}
	.about_mobile_infomain{display: block;box-shadow: 0 0 27px rgb(0 0 0 / 17%);border-radius: 8px;margin-bottom: 30px;}
	.about_info_lhs{box-shadow: none;padding: 0;}
	.about_info_rhs{max-width: 100%;padding: 25px 15px;}
	.about_info_lhs .myacc_product_imgdiv{height: 250px;z-index: 2;}

	.multi__rhs_li-left .member-help-block, .abt-backbtn-destop, .singletier_menumain{display: none;}
	.myacc_info_rhs_multi .member-mobile-block, .mobile_mnu_block{ display: block;}

	.tick img { width: 60px;}
	.merchant_header-tick h2{font-size: 24px;}
	.merchant_header-tick p{font-size: 15px;}
	.merchant-cartrhs>div h5{ font-size: 15px; }
	.merchant-cartrhs>div p{ font-size: 13px; }

	.tnk-detail h2 { font-size: 17px;}
	.tnk-order {padding: 20px 15px; }
	.tnk-order h3{ font-size: 18px; }
	.tnk-order p {font-size: 14px; }
	
	.delivery_total_left h2, .delivery_total_right h2{ font-size: 15px; }
	.delivery_total_left h4, .delivery_total_right h4{ font-size: 14px; }

	.merchant_innerheading{min-height: 100px;}
	.merchant_header-tick img{height: 65px;}
	.merchant_tnkyou { padding: 0;}

	.order-history-main {  padding: 30px 20px;}

	.accsec_right h1 { font-size: 26px;}
	.tab_mobtrigger{ font-size: 26px;}

	.merchent_serving .hservingnext{display: block;}
	.hservingnext a{padding: 14px 25px; text-align: center;font-size: 17px;}

	.htico_search{ position: static;}
	.hsearch_sec{ left: 15px; right: 15px; width: auto;top: 50px;}

	.customer_reward_main{
		padding-bottom: 0;
	}

	.customer_reward_main.customer_reward_block{
		padding-bottom: 80px;
	}

	.mobile_merchantdiv{
		padding-bottom: 0;
	}

	.merchent-reward-main {
	    padding: 40px 0 0;
	}

	.hservingnext a:after {
	    top: 9px;
	    font-size: 28px;
	}

	.login-rhs-body {
	    width: 100%;
		overflow: hidden;
	}

	.login-rhs-body input.form-control {
		width: 100%;
	}

	#register-popup .terms-chk .inline{
		font-size: 13px;
	}

	.customer-promotion-hand{
		margin-bottom: 20px;
	}

	.merchant-promotion-destop{
		display: none;
	}

	.merchant-tnk-maindiv{
		margin-top: 0px;
	}

	.merchant-voacher-mobile{
		width: 100%;
	}

	.home-banner {
		background-position: right -100px center;
	}

	.customer_mainlogin, .customer_Mainregister, .customer_Mlogsucess, .customer_Mvefification{
		background-position-x: 73%;
	}

	.merchant-voucher-tab{
		width: 100%;
	}

	.merchant-voucher-tab .voucher_ul{
		justify-content: space-between;
	}

	.promo-li {
		width: 49%;
		margin: 0 0 20px;
	}

	.myacc_content_main li .merbtn-redeem{
		color: #fff;
		border-radius: 8px;
		margin: 15px 0px 10px 0px;
		padding: 15px 26px 15px;
		background: #000;
		font-size: 15px;
	}

	.reward-list-body .promo-li, .mercnt-voucher-maindiv .voucher-li{
		width: 49%;
		margin: 0 0 20px;
	}

	.merchant-promotion-mobile{
		padding-top: 30px;
	}

	.reward-list-body h6{
		text-transform: capitalize;
	}

	.reward-list-body .voucher_ul, .mercnt-voucher-maindiv ul{
		justify-content: space-between;
	}

	.customer-voucher-tab{
		display: block;
	}

	.promo_product_li{width: 49%;margin: 0 0 20px;}
	.promotion_product_main ul{justify-content: space-between;}
	.promo_product_li:nth-child(2n){margin-right: 0;}
}

@media (max-width: 680px){
	.footer-newletter h4 { font-size: 16px; margin-bottom: 15px;}
	.promo_product_li .inner_img_cotnt, .mobile_voucher_uddiv .inner_img_cotnt,
	.mercnt-voucher-maindiv .voucher-li .inner_img_cotnt, .inner_img_cotnt {padding: 10px 20px;}

	.inner_img_lhs { width: 60%;}
	.inner_img_rhs { width: 40%;}
	.inner_img_rhs p{padding: 5px 4px;}

	.merchent-reward-inner{flex-flow: column;}
	.merchent-reward-rhs{width: 100%; padding: 0;}
	.merchent-reward-lhs{width: 100%;border-right: 0; padding: 0;}

	.login-innermain{justify-content: center;min-height: auto;flex-flow: column;}
	.login-lhs { width: 100%; height: 100%; border-bottom-left-radius: 0;border-top-right-radius: 15px;display: none;}
	.login-rhs { width: 100%;  padding: 35px 20px;}
	.login-popup { max-width: 420px;}

	.merchant-left-contentdiv h5{ display: flex; flex-flow: column; justify-content: center; align-items: center; }
	.merchant-left-contentdiv h5 span{ font-size: 14px; }
	.merchant-left-contentdiv p{ font-size: 12px; line-height: 1;}

	#otp-popup .login-rhs-body input.form-control {
	    margin: 0 0 10px;
	    width: 100%;
	}

	.logsucess-in {
	    width: 100%;
	}
}

@media (max-width: 620px){
	.footer-nav{display: none}
	.mobile-footer-nav{display: block;order: 4; width: 100%;padding-top: 20px}    
	.footer-newletter { width: 100%;} 
	#scrollbutton{ bottom: 40px; }
}

@media (max-width: 580px){ 
	.hcategory_trigger{font-size: 13px;padding-right: 20px; z-index: 21;}  
	.hcategory_trigger:after {width: 16px;height: 15px;}
	.hcategory_trigger.active:after {
	background: url(../images/close-block.png) no-repeat; background-size:contain;width: 14px;height: 14px;top: 3px;right: 5px;
	}     
	.hcategory_menulist>li>a {font-size: 15px;}
	.hcategory_menulist>li>a:hover {color: #c81d35;}
	.hcategory_submenu>li>a {font-size: 15px;padding-left: 35px;}
	.hcategory_selected_text {font-size: 15px;padding-right: 40px;}
	
	.homebanner-content h3 { font-size: 24px;}
	.myacc_product_contntdiv ul li p { margin: 0 5px 10px 0;}
	.myacc_product_contntdiv ul .cust_promoli{flex-flow: column;align-items: flex-start;}

	.inner_img_lhs { width: 50%;}
	.inner_img_rhs { width: 49%;}
	.inner_img_rhs p{padding: 8px 10px;}
	.promo_product_li .inner_img_cotnt{align-items: center;}
	.reward_datediv{display: none;}
	.reward-mbl-para p{display: block;}
	.customer_Ndashboard .welcome-title h1, .myacc_info_rhs_single .welcome-title h1, .myacc_info_rhs_multi .welcome-title h1{
		font-size: 16px;
	}
	.welcome-title h1 img{height: 25px;}
	.about-button li { margin-right: 5px;}
	.about-button li a{display: flex;flex-flow: column;font-size: 12px;}
	.about-button li img{margin: 0 0 5px; width: 25px; height: 25px;}
	.order-details-right .order-title, .order-title{font-size: 16px;}
	.acc-inform .col-md-6 { width: 100%;}
	.acc-inform .unitno_one, .acc-inform .unitno_two {padding: 0;}

	.welcome-title h1{ font-size: 18px; }

	.reward-mbl-para {
		align-items: flex-start;
	}

	.myacc_content_main li .merbtn-redeem{
		font-size: 14px;
	}

	.inner_img_lhs li h5, .used_voucher .inner_img_lhs li h4, .mobile_voucher_uddiv .inner_img_lhs li h4, .voucher-maindiv .inner_img_lhs li h4 {
		font-size: 17px;
	}
}

@media (max-width: 480px){
	.mobile-login { left: 40px;top: 11px;}  
	footer{padding: 30px 0 0 0 }  
/* Loyalty System Start*/
	.myacc_info_lhs, .myacc_info_rhs_single, .myacc_info_rhs_multi{ padding: 12px 12px;}
	.about_info_lhs{padding: 0;}
	.myacc_main_div .customer_Ndashboard { border-radius: 15px;}
	.promotions_info_mem li h3{font-size: 20px;}
	.member-help-block{text-align: left;}
	.mobile_acc_dbutton .button{font-size: 14px;}
	.homebanner-content-inner, .merchant-banner .homebanner-content-inner{padding: 25px 15px 15px;width: 380px;}
	.homebanner-content-inner form { justify-content: center; flex-flow: column; align-items: center;}
	.homebanner-content-inner input.form-control { width: 100%; margin: 0;}
	.homebanner-content-inner .react-tel-input{margin-bottom: 10px;}
	.customrt_product_main h5 { margin-bottom: 15px; font-size: 15px;}
	.mobile_tab_div ul li a { font-size: 14px; font-weight: 600;}
	.mobile_tab_div { padding: 20px 0 30px;}
	.promotion_innert_headind { padding: 0px 0 15px;}
	.homebanner-content { width: 100%; left: 0; padding: 0 20px;}
	.homebanner-content-inner .custm_password{ width: 100%;}
	.homebanner-content-inner .custm_password input.form-control{width: 100%;margin: 0 0 10px 0;}
	.homebanner-content-inner span.banner_mbl_error{ display: block; margin-bottom: 5px;  z-index: 1;}
    .homebanner-content-inner .home-phone{ display: none; }
	.destop-reward-ul li a{padding: 15px;}
	.destop-reward-ul{margin-bottom: 15px;}
	.outlet_list_inner{padding: 15px 20px;}
	.outlet_list_inner h5{font-size: 15px;font-weight: 600;}
	.merchebt_Rpoint li { width: 100px;}
	.merchent-mobile_rsh { padding: 20px 0 20px 15px;}
	.merchent-mobile_dbordblock{padding: 20px 15px;}
	.merchent-mobile_innername { padding: 5px 10px;}
	.merchent-reward-rhs, .merchent-reward-lhs{padding: 0;}
	.login-lhs-inner{padding: 20px;}
	.login-lhs-inner p{font-size: 14px;}
	.myacc_linkpara a{font-size: 12px;}
	.customer_Ndashboard .myacc_info_lhs .welcome-title{margin-bottom: 5px;}
	.about-button li a{letter-spacing: normal;}
    .noprodct-content p{ font-size: 14px; }

    .login_main_div h1{ font-size: 32px; margin-bottom: 20px;}
	.login_main_div h1 span{ font-size: 22px;}
	.login_main_div h6{font-size: 15px;}

	.merchant-cartlhs{ display: none; }
	.merchant-cartrhs>div { width: 100%; text-align: center;}
	.merchant-cartrhs .order-hlhs{ margin-bottom: 20px; }
	.merchantdate-main{ padding: 20px 0; }

	.delivery_total_left h2, .delivery_total_right h2 { font-size: 14px; }
	.delivery_total_left h4, .delivery_total_right h4 { font-size: 13px;}
	.cart_row.grant-total-cls p, .cart_row.grant-total-cls span { font-size: 20px;}

	.tnk-chk-order .button { padding: 15px 10px; font-size: 15px; letter-spacing: 0.5px;}
	.creat_link_main{display: none;}
	.mobile_creat_link_main{ display: block; margin: 0px 0 10px;}
	.merchebt_Rpointul p {width: 35px; height: 35px;}
	.merchebt_Rpoint li { height: 35px;}
	.merchebt_Rpointul h2 { font-size: 16px;}
	.container, .container-full {padding: 0px 15px;}
	
	.order-details-lhs, .order-details-rhs{width: 100%;}
	.order-details-rhs { padding: 10px 0 10px 10px;}
	.order-history-main{margin: 0px 0 85px;}
	.changepw_popup { padding: 40px 30px 30px;}
	.title1 { font-size: 24px; margin: 0 0 20px;}
	.accsec_right p {font-size: 15px;}
	.accsec_right h1 { font-size: 22px;}
	.order-details-content {  padding: 10px;}
	.order-details-left { display: flex; flex-flow: column;align-items: flex-end;}
	.order-details-lhs {border-bottom: 1px solid #efefef;}
	.order-details-main{padding: 10px;}
	.order-name { margin: 5px 0 0; text-align: right;}
	.myacc_main_div{padding-bottom: 40px;}

	.customer_reward_main .myacc_main_div{
		padding-bottom: 0px;
	}

	.customrt_product_main {
	    padding-bottom: 40px;
	}

	.merchent-reward-main {
	    padding: 20px 0 20px;
	}

	.merchant-left-contentdiv h5{ margin-bottom: 3px; }

	.merchent_serving {
	    padding: 10px 10px;
	}

	.merchant-all-rhs p{ font-size: 13px; }

	#otp-popup .prompt-box .input-box-small {
	    width: 35px;
	    height: 35px;
	}

	.merchant-social {
	    width: 70%;
	    text-align: center;
	    order: 2;
	}

	.merchant-footer-logo {
	    width: 20%;
	}

	.merchant-footer-top .footer-newletter {
	    width: 75%;
	    order: 3;
	    margin-top: 20px;
	}

	.merchant-footer-top{
		justify-content: flex-start;
		flex-wrap: wrap;
	}

	.merchant-footer-top .footer-newletter h4{ margin-bottom: 0 }

	.merchant-reward-whole{
    	padding: 20px 0 50px;
	}

	.customer-promotion-hand{
		border-radius: 15px;
	}

	.promotion-common-div{
	    padding-bottom: 40px;
	}

	.merchant-promotion-container{
	    padding-bottom: 0px;
	}

	.order-details-image, .order-details-image img{
		margin-bottom: 10px;
	}

	.order-date{
		text-align: right;
	}

	.myacc_info_lhs{
		border-radius: 12px;
	}

	.myacc_Dmain {
		padding-bottom: 40px;
	}

	.logo-main-section {
		height: 80px;
	}

	.merchant_logo {
		max-width: 80px;
	}

	.reward-list-body .promo-li, .mercnt-voucher-maindiv .voucher-li { width: 100%;}

	.merchant-promotion-mobile{
		padding-top: 20px;
	}

	.tier-benfits-main {
		padding: 40px 20px;
	}

	.inner_img_lhs li h5, .used_voucher .inner_img_lhs li h4, .mobile_voucher_uddiv .inner_img_lhs li h4, .voucher-maindiv .inner_img_lhs li h4{
		font-size: 16px;
	}

	.promo_product_li{width: 100%;}

	.voucher-name {font-size: 12px;}

	.voucher-name span { padding: 0 3px; }
}

@media (max-width: 420px){
	.homebanner-content-inner, .merchant-banner .homebanner-content-inner { width: 320px;}
	.myacc_product_option li a{ padding: 5px 8px 3px; font-size: 11px;}
	#page-numbers li{min-width: 30px;height: 30px;font-size: 14px;}
	.destop-reward-tab .tab-content {padding: 0 15px 20px;}
	.merchent-mobile_dbordblock .welcome-title h4{font-size: 18px;}
	.cart_left { width: calc(100% - 80px);}
	.tnx-right{ width: 80px;}

	.merchant-left-imgdiv {
	    padding: 8px;
	    height: 45px;
	    width: 45px;
	}

	.merchant-memberinner li{
		padding: 10px 10px;
		height: 100%;
	}

	.merchant-left-contentdiv{
		display: flex;
		flex: 1 1 auto; 
		flex-direction: column;
		height: calc(100% - 45px);
	}

	.merchant-left-contentdiv h5 {
		flex: 1 1 auto;
	}
}

@media (max-width: 380px){
	.homebanner-content-inner, .merchant-banner .homebanner-content-inner { width: 280px;}
	.homebanner-content-inner .react-tel-input .flag-dropdown.open .country-list{width: 280px;}

	.mobile_tab_div ul li a { font-size: 12px;}
	.myacc_product_li .myacc_product_option { flex-flow: column;}
	.myacc_product_option li{margin: 0 0 5px;}
	.inner_img_lhs li h5{font-size: 15px;}
	.reward_main li img { height: 25px;}
	.reward-mbl-para p { font-size: 11px;}
	.reward-mbl-para h5{font-size: 15px;}
	.reward_pointsdiv p{font-size: 12px;}
	.terms-chk .inline { font-size: 11px;}
	.login-lhs-inner h3{font-size: 18px;}
	.about-button li a{font-size: 10px;}
	.about_info_rhs { padding: 25px 10px;}
	.alert_fixed p { font-size: 12px;}
	.reedem_Inbtn { padding: 15px 30px 15px;}

	.myacc_product_imgdiv .myacc_credit a, .myacc_product_imgdiv .myacc_credit span{ line-height: 1; font-size: 14px; }

	.merchant-left-contentdiv h5 span {
	    font-size: 12px;
	}

	.merchant-left-contentdiv p{ font-size: 10px; }

	#otp-popup .login-rhs{ padding: 35px 15px; }

	#register-popup .terms-chk .inline {
	    font-size: 12px;
	    display: block;
	    text-align: left;
	    line-height: 1.2;
	}

	.prompt-box .input-box-small{
		border-radius: 6px;
		width: 35px;
		height: 35px;
		margin: 0 3px;
	}

	.cart_info h4{ font-size: 13px;}

	.myacc_product_option li a{ font-size: 9px;}
	.myacc_product_contntdiv h5{ font-size: 15px; margin-bottom: 10px;}
	.myacc_product_contntdiv{ padding: 10px 0 0;}

	.tier-content-para {
		padding: 0 40px;
	}
}












