
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'bessita_handwriting_freeRg';
    src: url('../fonts/bessita_handwriting_free-webfont.woff2') format('woff2'),
         url('../fonts/bessita_handwriting_free-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn,
 em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, 
 tbody, tfoot, thead, tr, th, td {
    border: 0;font-family: inherit;font-size: 100%;font-style: inherit;font-weight: inherit;margin: 0;outline: 0;padding: 0;
    vertical-align: baseline;}

html {
    -webkit-text-size-adjust: none;
    margin-right: 0 !important;
}

:focus {
    outline: 0;
}

ol,
ul {
    margin: 0;
    padding: 0px 0px 10px 40px;
}

table {
    border-collapse: separate;
    border-spacing: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

embed,
iframe,
object {
    max-width: 100%;
}

body {
    color: #000;
    background: #fff;
    word-wrap: break-word;
    font-size: 15px;
    font-family: 'Poppins';
    line-height: 1.5;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

cite, em, i { font-style: italic; }

input, textarea {
    -webkit-border-radius: 0px;
    border-radius: 0;
}

input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
textarea,
select {
    padding: 12px 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    margin: 0 0 10px;
    background-color: #fff;
    border-radius:0px;
    height: 48px;
    font-size: 15px;
    color: #555555;
}

input[type="reset"], input[type="button"], input[type="submit"], .button, button {
    color: #fff;
    display: inline-block;
    border-radius: 8px;
    margin: 15px 0px 10px 0px;
    padding: 15px 26px 15px;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    cursor: pointer;
    background: #000;
    border: 0px;
    font-size: 15px;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    font-family: 'poppins';
    letter-spacing: 1px;
    font-weight: 500;
}

input[type="reset"]:hover, input[type="button"]:hover, input[type="submit"]:hover, .btn:hover, .button:hover, button:hover {
    color: #fff;
    background: #f12148;
}

input[type="reset"]:active, input[type="button"]:active, input[type="submit"]:active {
    -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
}

a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

a:focus,
a:active,
a:hover {
    text-decoration: none;
}

a:hover {
    color: #db1746;
}

figure {
    margin: 0;
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    font-weight: normal;
    line-height: 1.1;
    color: #1a1a1a;   
    font-family: 'poppins';
    font-weight: 600;
    text-transform: uppercase;
}

h1 { font-size: 42px;}
h2 { font-size: 35px;}
h3 { font-size: 26px;}
h4 { font-size: 22px;}
h5 { font-size: 17px;}
h6 { font-size: 16px;}


.show {display: block;}
.hidden { display: none;}
.invisible { visibility: hidden;}

.fl {
    float: left
}

.fr {
    float: right
}

.rel {
    position: relative
}

.textcenter {
    text-align: center
}

.textleft {
    text-align: left
}

.textright {
    text-align: right
}

*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.clear,
.clearfix {
    clear: both;
}

.clearfix:after {
    clear: both;
    display: table;
    content: ""
}

strong,
b {
    font-family: 'poppins';
    font-weight: 600;
}

.required_star {
    color: #f00;
}

.container {
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.slick-slider,
.slick-slider * {
    outline: none;
}

p {
    margin: 0 0 15px;
    font-family: 'Poppins';
}

.title_sec {
    margin: 0 0 45px;
    color: #040507;
}

.title_sec h2 {
    font-size: 44px;
    line-height: 1;
    margin: 0 0 5px;
}

.title_sec small {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 13px;
}

.title1 {
    font-size: 32px;
    color: #040507;
    line-height: 1;
    margin: 0 0 24px;
}

.title3 {
    font-size: 20px;
    color: #040507;
    line-height: 1;
}

.title4 {
    font-size: 18px;
    color: #010101;
}

.icon-cap {
    background: url(../images/icon-cap.png) no-repeat;
    width: 34px;
    height: 31px;
    display: block;
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}

.table_overflow {
    overflow-x: auto !important;
    overflow-y: hidden !important;
    margin-bottom: 20px;
}

.table_overflow .table {
    margin-bottom: 0;
}

.dropdown-menu>li>a {
    padding: 7px 20px;
    border-top: 1px solid #e9ebf5;
}

.dropdown-menu>li:first-child>a {
    border-top: 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
}

.form-group {
    margin: 0 0 8px;
}

label {
    font-size: 14px;
    color: #959595;
}

label span {
    color: #f00;
    margin-left: 3px;
}

.manitatory-select .Select-placeholder:after {
    content: '*';
    color: red;
}

.form-control {
    height: 48px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 6px 18px;
    background: #fff;
    font-size: 15px;
    border-radius: 0;
    color: #615f5f;
    width: 100%;
    display: block
}

textarea.form-control {
    height: 160px;
}

textarea.form-control.sm {
    height: 90px;
}

.form-control.error,
.Select-control.error {
    border-color: #FFBABA;
    color: #686a78;
}

.form-control:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: #000000;
}

.focus-out {
    position: relative;
}

.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.clock_icon,
.ileft_ico {
    position: absolute;
    left: 0;
    top: 0;
    width: 40px;
    height: 100%;
}

.clock_icon img,
.ileft_ico img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
}

.profile-info-div {
    margin: 35px 0;
}

.other-add {
    margin: 30px 0 0 0
}

.custom_select {
    position: relative;
}

.custom_select:after{
    content: "";
    background: #fff url(../images/arrow-down.png) no-repeat center;
    width: 45px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    pointer-events: none;
    height: 46px
}

.react-datepicker {
    font-family: 'Poppins' !important;
}

.react_datepicker .react-datepicker-wrapper, .react_datepicker .react-datepicker__input-container { display: block; }

.react_datepicker .react-datepicker { font-size: 1rem;}

.react_datepicker .react-datepicker__current-month, .react_datepicker .react-datepicker-time__header {
    font-size: 1.2rem;
    font-weight: bold
}

.react_datepicker .react-datepicker__day-name, .react_datepicker .react-datepicker__day,
.react_datepicker .react-datepicker__time-name {
    width: 2.7rem;
}

.react_datepicker .react-datepicker__time-container { width: 120px; }

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    padding: 0;
}

.react_datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 120px;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected {
    background-color: #f12148 !important
}

.react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover {
    background-color: #f12148 !important;
}

.react-datepicker__header {
    background-color: #f1f1f1 !important;
}

.react-datepicker__navigation {
    margin: 0;
    box-shadow: none;
}

.react-datepicker__navigation--previous {
    border-right-color: #000 !important;
}

.react-datepicker__navigation--next {
    border-left-color: #000 !important;
}

.react-datepicker__navigation--previous:hover,
.react-datepicker__navigation--next:hover {
    background: none
}

.react-datepicker__month-dropdown-container--select select,
.react-datepicker__year-dropdown-container--select select {
    height: 36px;
    padding: 5px 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, 
.react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, 
.react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, 
.react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range{ border-radius: 50% !important;     background-color: #f12148 !important}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #f12148 !important;
}

/* Custom Radio Check Box*/
@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type=checkbox], input[type=radio] {
    --active: #da203a;
    --active-inner: #fff;
    --focus: 2px rgba(0, 0, 0, .3);
    --border: #757575;
    --border-hover: #000;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #999;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 18px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type=checkbox]:after, input[type=radio]:after {
    content: "";
    display: block;
    left: -1px;
    top: -1px;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
  }
  input[type=checkbox]:checked, input[type=radio]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: .3s;
    --d-t: .6s;
    --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
  }
  input[type=checkbox]:disabled, input[type=radio]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type=checkbox]:disabled:checked, input[type=radio]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type=checkbox]:disabled + label, input[type=radio]:disabled + label {
    cursor: not-allowed;
  }
  input[type=checkbox]:hover:not(:checked):not(:disabled), input[type=radio]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type=checkbox]:focus, input[type=radio]:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type=checkbox]:not(.switch), input[type=radio]:not(.switch) {
    width: 18px;
  }
  input[type=checkbox]:not(.switch):after, input[type=radio]:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type=checkbox]:not(.switch):checked, input[type=radio]:not(.switch):checked {
    --o: 1;
  }
  input[type=checkbox] + label, input[type=radio] + label {
    line-height: 21px;
    display: inline-block;
    cursor: pointer;
    margin-left: 5px !important;
  }

  input[type=checkbox]:not(.switch) {
    border-radius: 3px;
  }
  input[type=checkbox]:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 6px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }
  input[type=checkbox]:not(.switch):checked {
    --r: 43deg;
  }
  input[type=checkbox].switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type=checkbox].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type=checkbox].switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type=checkbox].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  input[type=radio] {
    border-radius: 50%;
  }
  input[type=radio]:after {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  input[type=radio]:checked {
    --s: .5;
  }
}

.error {
    color: #f00;
    font-weight: normal;
}

span.error {
    background: #FFBABA;
    color: #D8000C;
    padding: 5px 10px;
    font-size: 11px;
    position: relative;
    display: inline-block;
    font-weight: normal;
    z-index: 4;
    line-height: 1.1;
}

span.error:before {
    content: "";
    border-bottom: 5px solid #ffbaba;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
}

.alert {
    padding: 10px 15px;
    border-radius: 0;
    -webkit-border-radius: 0;
    text-align: left;
    display: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
}

.alert_fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
    max-width: 1260px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.alert_success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert_danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert_fixed p {
    margin: 0 auto;
}

.checkout-main-div .custom_center_alertcls.alert_fixed a {
    font-size: 24px;
    right: 20px;
    top: 22px;
    position: absolute
}

.alert_fixed a {
    top: 5px;
    font-size: 24px;
    right: 20px;
    position: absolute
}

.display_inblock { display: inline-block;}
.display_block { display: block;}
.relative {position: relative;}
.vtop { vertical-align: top;}
.vmiddle { vertical-align: middle;}
.vbottom { vertical-align: bottom;}

.vmid_sec:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}

.vmid_in {
    display: inline-block;
    vertical-align: middle;
    width: 98%;
}

.modal-dialog {
    margin: .5rem;
}

.modal-dialog-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - (.5rem * 2));
    min-height: -webkit-calc(100% - (.5rem * 2));
}

.modal-content {
    width: 100%;
    box-shadow: none;
    -webkit-box-shadow: none;
    border-radius: 14px;
    border:1px solid #999;
}

.poup_wrap {
    width: 660px;
}

.popup_in {
    padding: 28px 60px 54px;
}

.close_popup {
    font-size: 38px;
    font-weight: normal;
    line-height: 32px;
    position: absolute;
    right: 10px;
    top: 10px;
    height: 32px;
    width: 32px;
    color: #231f20;
    text-align: center;
}

.chosen-container .chosen-results .highlighted { background: #ecdfcc; }

button.mfp-close:hover, button.mfp-arrow:hover {
    background: none;
}

.desktop_hmenu_list .submenu-arow {
    display: none
}

.mobile_hmenu_list .submenu-arow {
    display: block;
    width: 40px;
    background: #e6e6e6;
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
}

.submenu-arow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    margin: auto;
    margin-top: -9px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    display: block !important;
    color: #000;
}

.open-submenu .submenu-arow:after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block !important;
}

.hsearch_sec, .hsearch_sec_mbl {
    width: 380px;
    position: absolute;
    right:0px;
    top: 40px;
    opacity: 0;
    pointer-events: none;
    -webkit-transition: all 400ms;
    transition: all 400ms;
    z-index: 3;
    visibility: hidden;
}

.hsearch_sec.open, .hsearch_sec_mbl.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
}

.hsearch_bx {
    position: relative;
}

.hsearch_bx .form-control {
    height: 48px;
    border: 0;
}

.hsearch_bx .btn {
    background: #ecdfcc url(../images/search.png) no-repeat center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 65px;
    border-radius: 0;
    margin: 0;
    height: auto;
    padding: 0;
}

.hsearch_bx .btn:hover {
    background-color: #1c1c1c;
}

.hsearch_bx .re_select .Select-control {
    border: 0;
    border-radius: 8px;
    -webkit-box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    box-shadow: 4px 0 6px 0 rgba(24, 24, 24, 0.23);
    overflow: visible;
    height: 54px;
}

.hsearch_bx .re_select .Select-arrow {
    opacity: 0;
}

.hsearch_bx .re_select .Select-placeholder {
    line-height: 52px;
    padding: 0 26px;
    color: #abaaa8;
}

.hsearch_bx .re_select .Select-input {
    height: 54px;
    padding: 0 26px;
    float: left;
}

.hsearch_bx .re_select .Select-input > input {
    line-height: 34px;
}

.hsearch_trigger_img img {
    width: 26px
}

.hsearch_trigger_img {
    margin: 5px 0 0 10px;
}

.hmenu_sec ul {
    padding: 0;
    list-style: none;
    text-align: initial;
}

.hmenu_list {
    margin: 0;
}

.hmenu_list > li {
    display: inline-block;
}

.hmenu_list > li > a > span {
    position: relative;
}

.hmenu_actions > li {
    list-style: none;
    position: relative;
}

.hordertype_sec a, .merchabt_login a {
    display: block;
    padding: 14px 30px;
    color: #1a8ce7;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #1a8ce7;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 600;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 15px 0 25px;
    line-height: 1;
}

.merchabt_login a{
    margin: 0;
}

.htico_sign a:after {
    content:"\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: 20px;
    line-height: 1;
}

.hordertype_sec a:hover, .merchabt_login a:hover {
    color: #fff;
    background: #1a8ce7;
}

.submenu_list {
    margin: 0;
    padding: 0px;
    background: #fff;
    z-index: 99;
    width: 220px;
    position: absolute;
    right: -25px;
    top: 55px;
    display: none;
    text-align: center;
    box-shadow: 0px 10px 39.2px 0.8px rgb(0 0 0 / 10%);
}

.hmenu-login-act li:hover ul, .hmenu-login-act li ul li:hover>ul {
    display: block;
}

.open-popup-link.htico_sign, .hsign_sec .htico_sign{
    color: #225ca5;
}

.open-popup-link.htico_sign:hover, .hsign_sec .htico_sign:hover, 
.open-popup-link.htico_sign.active, .hsign_sec .htico_sign.active{
    color: #225ca5;
}

.hmenu-login-act .submenu_list li a {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 15px;
    color: #000;
    text-transform: capitalize;
    padding: 10px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078)
}

.submenu_list li a:hover {
    color: #1a8ce7;
}

.hmenu_sec .mobile_hmenu_list ul.submenu_list {
    display: none;
    background: #f7f7f7
}

.mobile_hmenu_list ul.submenu_list li a {
    display: block;
    padding: 10px 15px;
    font-family: 'poppins';
    font-weight: 600;
    font-size: 16px;
    color: #333;
    border-bottom: 1px solid #e4e4e4;
}

.mobile_hmenu_list ul.submenu_list li a:hover { color: #f12148;}

.hsearch_sec .search_result, .hsearch_sec_mbl .search_result_mbl, .product_search_result {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    max-height: 300px;
    overflow-y: auto;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    display: none;
}

.search-product-main .spm-lhs .search_result{ width: 100%;}
.product_search_result{position: absolute; top: 100%; left: 0;z-index: 5;width: 100%;}

.search_result li, .search_result_mbl li, .product_search_result li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.090);
}

.search_result li a, .no-pro-found, .search_result_mbl li a, .product_search_result li a {
    color: #333;
    display: block;
    padding: 9px 15px
}

.search_result li a:hover, .search_result_mbl li a:hover, .product_search_result li a:hover {
    background: #49947c;
    color: #fff;
}

.hsearch_sec.open .input-sec, .hsearch_sec_mbl.open .input-sec {
    position: relative
}

.hsearch_sec.open .input-sec input, .hsearch_sec_mbl.open .input-sec input  {
    padding-right: 10px;
    margin-bottom: 0;
    border-color: rgba(0, 0, 0, 0.3);;
}
.htico_search{position: relative; }
.search_text_clear {
    position: absolute;
    right: 10px;
    top: 11px;
    display: none
}

.htico_cart {
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
}

.htico_cart > a {
    position: relative;
    display: block;
}

.hcartdd_trigger.active:before {
    opacity: 1;
    visibility: visible;
}

.cart_left .help-block {
    font-style: italic;
    color: #a7a4a4;
    margin: 5px 0 0;
    font-size: 14px;
    padding-left: 10px;
}

.cart_extrainfo {
    color: #3d3d3d;
}

.cart_extrainfo p b {
    color: #959595;
    font-weight:normal;
    font-size: 13px;
}

.cart_extrainfo p, .notes-block {
    margin: 0;
    color: #959595;
    font-size: 13px;
    padding-left: 10px
}

.cart_remove {
    background: #b0b0b0;
    position: absolute;
    top: 10px;
    right: -8px;
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart_remove:hover {
    background-color: #000;
}
.cart_remove img{
    width: 8px;
}

.qty_bx {
    width: 213px;
    height: 60px;
    position: relative;
    padding: 0 60px;
    display: inline-block;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 50px;
}

.qty_bx span {
    position: absolute;
    width: 60px;
    height: 100%;
    display: block;
    top: 0%;
    bottom: 0;
    text-indent: 9999px;
    overflow: hidden;
    cursor: pointer;
    margin: auto;
    background: #e0e6ee;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    border-radius: 50px;
}

.qty_bx span.qty_minus {
    left: 0px;
    border: 2px solid #e0e6ee;
}

.qty_bx span.qty_plus {
    right: 0px;
    background: #363637;
    border: 2px solid #3e3e3f;
}

.qty_bx input {
    height: 100%;
    border: none;
    color: #000;
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding: 2px;
    background: #fff;
    border-radius: 5px;
    font-weight: bold;
}

.qty_bx span.qty_minus:before{
    position: absolute;
    content: "";
    background: #000;
    width: 14px;
    height: 2px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}

.qty_bx span.qty_plus:before, .qty_bx span.qty_plus:after {
    position: absolute;
    content: "";
    background: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
}
.qty_bx span.qty_plus:before{
    width: 14px;
    height: 2px;
}
.qty_bx span.qty_plus:after{
    width: 2px;
    height: 14px;    
}

.cart_footer span { font-size: 16px; font-family: 'poppins'; font-weight: 600;}
.cart_footer .cart_row { display: flex;justify-content: space-between; margin-bottom: 5px;}
.cart_footer .cart_row p{margin: 0;  font-family: 'poppins'; font-weight: 600;}
.cart_footer_totrow p {  font-size: 25px;}
.cart_footer_totrow p.help-block { font-size: 14px; font-weight: 300;}
.cart_footer_totrow span { font-size: 25px;}

.indication_progress {
    height: 12px;
    overflow: hidden;
    border-radius: 10px;
    background: #f12148;
    position: relative
}

.progress_bar {
    content: "";
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    background:#f7d3bd;
    border-radius: 10px;
    -webkit-transition: 0.4s linear all;
    transition: 0.4s linear all;
}

.indication .help-block {
    color: #cdaf9d;
    font-size: 14px;
    font-weight: 400;
    margin: 7px 0 0;
}

.cart_action .btn_grey {
    margin: 0;
    border-color: #e6e3e3;
}

.cartaction_price {
    font-size: 24px;
    width: 30%;
    cursor: default;
}

.cartaction_price sup {
    font-size: 50%;
    left: -4px;
    top: -7px;
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

header .cart_action{  padding: 20px 20px 0 20px}

.header_top {
    background: #181818;
    position: relative;
}

.htopinfo {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    color: #fff;
    padding: 10px 0;
}

.htopinfo p {
    margin: 0;
}

.pop-alert-icon {
    padding: 0 0 15px;
}

.header_bot {
    position: relative;
}

.sticky .alert_fixed {
    top: 95px;
}

.scrollToTop {
    background: rgba(0, 0, 0, 0.7);
    width: 40px;
    height: 40px;
    line-height: 36px;
    position: fixed;
    bottom: 40px;
    right: 20px;
    font-size: 24px;
    border-radius: 2px;
    text-align: center;
    color: #fff;
    z-index: 1;
}

.scrollToTop:hover, .scrollToTop:focus {
    background: rgba(0, 0, 0, 1);
    color: #fff;
}

textarea {
    resize: none
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    outline: 0;
}

.inputfile + label {
    font-size: 16px;
    font-weight: 700;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    padding: 11.5px 15px;
    outline: 0;
    background: #ecdfcc;
    max-width: 160px;
    border: 1px solid #e6e5e5;
    text-overflow: ellipsis;
}

.inputfile + label:hover {
    background: #333;
}

.inputfile + label:hover span {
    color: #fff;
}

.no-js .inputfile + label {
    display: none;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #666;
    margin-top: -19px;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #d0cfcb;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 20px;
    height: 20px;
    background: url(../images/tick-20.png) no-repeat;
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.alert_popup, .warining_popup, .warning_popup, .pay-conf-popup {
    max-width: 450px;
}

.warning_popup .warning-popup-img {
    width: 75px;
    margin: 0 0 10px 0
}

button.mfp-close, button.mfp-arrow {
    margin: 0;
    padding: 0
}

.alert_header {
    background: #f12148;
    color: #fff;
    font-size: 20px;
    padding: 12px 14px;
    position: relative;
    text-align: center;
    font-family: 'poppins';
    font-weight: 600;
    text-transform: uppercase
}

.alert_body {
    text-align: center;
    padding: 20px;
}

.alt_btns a {
    margin: 0 5px;
}

.alt_btns a.button-left {
    background: #282828
}

.popup_equalrw {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_equalrw, .popup_ttsec {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
     -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.popup_ttsec {
    width: 42%;
    min-height: 310px;
    padding: 20px 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.pop_title {
    position: relative;
    color: #fff;
}

.pop_title h2 {
    font-size: 36px;
    margin: 0;
    line-height: 1;
    color: #fff;
    text-transform: inherit;
    font-weight: 600;
}

#delevery-postcode-popup .pop_title h2{
    text-transform: uppercase;
    font-family: 'poppins';
    font-weight: 600;
}

.pop_title small {
    font-size: 18px;
    font-weight: normal;
}

.popup_right {
    width: 58%;
    padding: 20px 60px 20px 68px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.or-seperator-row {
    position: absolute;
    right: -9px;
    z-index: 1;
    top: 6px;
}

.or-seperator-row .or-seperator {
    position: relative;
    padding: 25px 0px 7px 0;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator-row .or-seperator span {
    padding: 7px 0px;
}

.or-seperator-row .or-seperator:after {
    height: 82px;
    width: 1px;
    background: #e5cda6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.gloading_img {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 9;
}

.gloading_img:after {
    content: "";
    width: 35px;
    height: 35px;
    border: 4px solid rgba(30, 29, 29, 0.49);
    border-top-color: #1e1d1d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 1s linear infinite;
    animation: loader-rotate 1s linear infinite;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.load_warpper {
    position: relative;
}

.load_warpper #dvLoading {
    position: absolute;
}

#dvLoading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: #fff url(../images/loading.gif) no-repeat center;
    background-size: 60px;
    -webkit-background-size: 60px;
    bottom: 0;
    right: 0;
}

#inner_loading {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9) url(../images/loading_inner.gif) no-repeat center;
    bottom: 0;
    right: 0;
}

.popup_close {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 5px;
    width: 40px;
    z-index: 1
}

.react-datepicker-popper {
    z-index: 11 !important;
}

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 50px;
    right: 15px;
    background: #523429;
    color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
    cursor: pointer;
    z-index: 111;
}

.scrollup:hover {
    background: #000;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scrollup:hover .fa {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.scrollup .fa {
    transition: all 0.4s ease 0s;
    -webkit-transition: all 0.4s ease 0s;
}

.cms-page {
    margin: 0px 0 30px;
}

.cms-page .container {
    background: #fff;
    margin: 25px auto;
    padding: 40px 20px 30px;
}

.cms-page h3 {
    font-size: 27px;
    color: #040507;
}

.loader-main-cls {
    position: relative;
    pointer-events: none;
}

.loader-sub-div {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 98;
}

.loader-sub-div:after {
    width: 35px;
    height: 35px;
    border: 4px solid #f12148;
    border-top-color: #06453d;
    border-radius: 50%;
    position: relative;
    -webkit-animation: loader-rotate 0.3s linear infinite;
    animation: loader-rotate 0.3s linear infinite;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto
}

@-webkit-keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader-rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.header_middle .container {
    position: relative;
}

.htico_search > a, .htico_search_mbl > a {
    position: relative;
    display: block;
}

.header_bot {
    display: inline-block;
}

.header-top {
    background: #000;
    text-align: center;
    clear: both;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-top p {
    color: #fff;
    font-size: 15px;
    margin-bottom: 0px;
}
.header-top-cls {
    padding: 6px 0;
    position: relative;
}

.logo-main-section {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 9;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: space-between;
}
.menu_icon.trigger_menunav_act {
    display: none;
}
.mbl_search_liblk{display: none}
.container-full{ 
    max-width: 1340px;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
}

.slick-track {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.slick-track:before,
.slick-track:after {
    display: none !important
}

.slick-track .slick-slide {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: inherit;
}

.slick-track .slick-slide .featured-products-single {
    height: 100%;
    background-color: #fff;
}

header {
    position: -webkit-sticky;
    position: sticky;
    top: -1px;
    z-index: 99;
    margin: auto;
    box-shadow: -5.44px -4.41px 7px #00000026;
    background: #fff;
}

.header-bottom .header-bottom-innersec {
    position: relative;
}

ul.hmenu_list > li > a .sub-menu {position: relative;}
ul.hmenu_list > li > a .sub-menu::after {
    content: "";
    position: relative;
    display: inline-block;
    background: url(../images/next.png) no-repeat;
    width: 16px;
    height: 9px;
    right: -10px;
}

.hsign_sec > span {
    font-size: 17px;
    vertical-align: text-bottom;
    position: relative;
    top:2px;
    color: #fff;
    margin: 0 5px;
}

.hsign_sec > a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 15px;
    color: #000;
    text-transform: capitalize;
    line-height: 1.8;
}

.hsign_sec a:hover, .hsign_sec a.active {
    color: #1a8ce7;
}

.hdropdown-list .submenu-arow {
    padding: 0;
    margin: 0 0 0 4px;
    width: 14px;
}

.haction_sec {
    text-align: center;
    position: relative;
}

.hmenu_actions {
    position: absolute;
    top:10px;
    right: 0;
    padding: 0;
     display: -webkit-box;
     display: -ms-flexbox;
     display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.htico_search> a, .htico_wishlish> a, .htico_cart> a {
    width: 25px;
    height: 25px;
    display: block;
    line-height: 1.6;
}

.htico_cart> a img, .htico_search> a img, .htico_wishlish> a img{
    vertical-align: middle; 
}

.header_in {
    padding-top: 45px;
    position: relative;
}

.hcart_round {
    background: #1a8ce7;
    min-width: 22px;
    min-height: 22px;
    line-height: 22px;
    font-size: 10px;
    border-radius: 100%;
    position: absolute;
    right: -12px;
    top: -16px;
    text-align: center;
    color: #fff;   
    font-family: 'poppins';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.page404-main-div {
    text-align: center;
      height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
   -webkit-box-orient: vertical;
   -webkit-box-direction: normal;
   -ms-flex-flow: column wrap;
   flex-flow: column wrap;
}

.page404-main-div .gohome {
    display: inline-block;
    line-height: 1;
    font-weight: bold;
    background: #000;
    padding: 15px 50px;
    border-radius: 4px;
    text-align: center;
    background: #000;
    margin: 10px;
    -webkit-transition: 0.3s linear all;
    transition: 0.3s linear all;
    color: #FFF;
}
.page404-main-div h1{     
    margin-bottom: 10px;
    margin-top: 40px;}
.product-rhs .product_orders_top{display: none}

.cart_row.grant-total-cls sup {
    font-size: 60%;
    position: relative;
    top: -6px;
}

.cart_footer .cart_row .row-replace {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.delivery-cart-div h4 {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 17px;
    text-transform: uppercase;
    margin: 0 0 5px 0;
    font-family: 'Poppins';
}

.delivery-cart-div p {
    color: #000000;
    margin: 0
}

.cart-header-second h5 {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 16px;
    text-transform: uppercase;
    margin: 0 0 5px 0
}

.cart-header-second p {
    font-weight:bold;
    color: #1c1b1b;
    font-size: 28px;
}

.cart_footer .cart_remove {
    top: 4px;
    right: 0px;
}

.cartaction_bottom .btn {
    margin: auto;
    width: 100%;
    text-align: center;
    display: block;
    background: #f7c55a;
    padding: 10px 10px;
    border-radius: 5px;
    height: auto;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #fff;
    font-family: 'poppins';
    font-weight: 600;
    text-transform: uppercase;
    transition: 0.3s linear all;
}

.cartaction_bottom .btn:hover {
    background: #000;
}
/*.cart_row.grant-total-cls {
    padding-top: 10px;
    margin-bottom: 20px;
}*/

#cart-close-span{    
    display: block;
    position: relative;
    order: 2;
    width: 24px;
    height: 22px;
    cursor: pointer;
}

#cart-close-span:before, #cart-close-span:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #fff;
}
#cart-close-span:before {
    transform: rotate(45deg);
}
#cart-close-span:after {
    transform: rotate(-45deg);
}
.delivery-cart-div, .cart-header-second{display: flex; justify-content: space-between; flex-wrap: wrap;}
.delivery-cart-div .col-sm-cls, .cart-header-second .col-sm-cls { width: 48%;}

.gst-row {
    margin-top: 15px;
}
.productlist-main-div {
    padding: 20px 0 140px;
    width: 100%;
}

.form-group.half-fg {
    display: flex;
    justify-content: space-between;
}
.form-group.half-fg .input_field{ width: 49%; }
.cart_body .hclear_cart:hover {
    color: #bf1a18;
}

.alert-success {
    background-color: #dff0d8;
    color: #3c763d;
    border-color: #d6e9c6;
}

.custom_close span {
    color: red;
}

.alert-success:before,
.single-danger:before {
    top: 10px;
    left: 8px
}

.jquery-success-msg {
    margin-left: 20px;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.btn-medium {
    min-width: 190px;
}

.cart-header-second {
    padding: 15px 0 0 0;
    width: 100%;
}

.hcart_dropdown .cart-header-first .cart_left h4{
    margin-bottom: 10px;
}

.hcart_dropdown .cart-header-first .cart_left p{
    margin-bottom: 5px;
}

.cart-direction {
    margin: 0 0 15px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.head_right .head-group {
    z-index: 2;
    position: relative
}

.head_left h4 {
    color: #000000;
    margin: 0;
    font-size: 18px;
}

.head_right h4 {
    color: #fff;
    margin: 0;
    text-transform: capitalize;
    font-size: 16px;
}

.head_right::before {
    content: "";
    display: block;
    background: #000;
    width: 35%;
    position: absolute;
    right: -30px;
    height: 100%;
    top: 0;
    -webkit-transform: skew(-39deg);
    transform: skew(-39deg);
    z-index: 1;
}

.order_details_footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.errorInfo-div {
    display: none;
    background: #ef1616;
    padding: 5px;
    color: #fff;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}

.err_alt {
    border: 1px solid #ff1313 !important;
}

.err_tarms_chk span {
    color: #ff1313;
}

#scrollbutton {
    position: fixed;
    bottom: 60px;
    right: 30px;
    width: auto;
    height: auto;
    z-index: 50;
    opacity: 0;
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton.show {
    display: inline-block;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

#scrollbutton a {
    background: #252525;
    display: inline-block;
    position: relative;
    color: #ea1f4c;
    text-decoration: none;
    padding: 0;
    overflow: hidden;
    height: 38px;
    width: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 100px;
    font-size: 18px;
}

#scrollbutton a i.ars {
    -webkit-transition: all .2s linear .2s;
    transition: all .2s linear .2s;
}

#scrollbutton a:hover i.ars {
    transform: translateY(-25px) rotateY(360deg);
    opacity: 0;
    transition: all 0.3s linear 0.2s;
}

#scrollbutton a:hover i.ars2 {
    bottom: 0%;
    transform: translate(-50%, -50%) rotateZ(0deg);
    transition: all 0.2s linear 0.4s;
}

#scrollbutton a:hover i.ars1, #scrollbutton a:hover i.ars2 {
    bottom: 10%;
    opacity: 1;
    -webkit-transition: all .2s linear .2s;
    transition: all .2s linear .2s;
}

#scrollbutton a:hover i.ars1, #scrollbutton a:hover i.ars2, #scrollbutton a i.ars2 {
    -webkit-transform: translate(-50%,-50%) rotate(0deg);
    transform: translate(-50%,-50%) rotate(0deg);
}

#scrollbutton a i.ars1, #scrollbutton a i.ars2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%,-50%) rotate(1turn);
    transform: translate(-50%,-50%) rotate(1turn);
    opacity: 0;
    -webkit-transition: all .2s linear .2s;
    transition: all .2s linear .2s;
}

#scrollbutton a i.ars2 {
    transform: translate(-50%, -50%) rotateZ(0deg);
    transition: all 0.2s linear 0s;
}

#scrollbutton a:hover {
    background: #ec194b;
    color: #fff;
}

.error_div_cls, .error_div_cls_limit {
    color: red;
    display: none
}

.progress_bar_div{ margin-top: 10px}

.common-inner-banner{ 
    position: relative;
    padding: 30px 20px;
    color: #000; 
    -webkit-box-align: center; 
    -ms-flex-align: center; 
    -webkit-box-orient: vertical; 
    -webkit-box-direction: normal; 
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column; 
    background:url(../images/my_accbanner.png) no-repeat; background-size: cover; min-height: 176px; 
} 
.common-inner-banner p {
    line-height: 1.2;
    color: #fff;
    font-size: 16px;
    font-family: 'poppins';
}

.common-inner-banner h3{
    font-size: 34px;
    font-family: 'poppins';
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1;
    color: #fff;
    margin-bottom: 12px;
}

.mbl-hiden{overflow-y: hidden}

.mobile-order-nowbtn a{
    padding: 16px 15px;
    background: #f12148;
    font-size: 16px;
    transition: all .3s ease;
    text-transform: uppercase;
    line-height: 1;
    text-align: center;
    font-family: 'Poppins';
    font-weight: 600;
    display: block;
    color: #fff;
    letter-spacing: 0.5px;
}
.mobile-order-nowbtn a:hover{ background: #bf1a18;color: #fff;}
.mobile-login{display: none}
.mobile-login ul {
    padding: 0;
    list-style: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: #000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-width: 100%;
    visibility: hidden;
    opacity: 0;
    transform: translateY(2em);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;
    box-shadow: -1px 1px 3px #00000021;
    overflow: hidden;
    
}
.mobile-login ul.active{
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
}

.mobile-login {
    position: relative;
}

.mobile-login ul li a {
    position: relative;
    display: block;
    line-height: 1;
    padding: 10px 20px;
    font-family: 'Poppins';
    font-size: 18px;
    text-transform: capitalize;
    color: #fff;
    border-bottom: 1px solid hsla(0,0%,100%,.22);
}
.mobile-login ul li a:hover {
    background: #f12148;
    color: #fff;
}

.mobile-login ul li a:last-child::after {display: none;}
a.controller-nav img { width: 32px;}

.mobile-menu {
    position: fixed;
    padding: 0;
    overflow: hidden;
    background-color: #ffffff;
    height: 100vh;
    left: 0;
    top: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, -webkit-transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out;
    transition: opacity .2s linear, transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    width: 100vw;
    z-index: 9999;
    max-width: 520px
}

.mobile-menu .menu {
    list-style: none;
    padding: 0;
    margin: 0 0 30px
}

.mobile-menu .menu li {
    list-style: none;
    position: relative
}

.mobile-menu .mobile-menu-body {
    overflow: hidden;
    overflow-y: auto;
    padding: 50px 40px 40px;
    height: 100%
}

.mobile-menu.active {
    -webkit-transform: translateX(0);
    transform: translateX(0)
}

.mobile-menu.active .menu a {
    -webkit-animation: a .4s .2s backwards;
    animation: a .4s .2s backwards
}

.mobile-menu.active .menu li:nth-of-type(2) a {
    -webkit-animation-delay: .3s;
    animation-delay: .3s
}

.mobile-menu.active .menu li:nth-of-type(3) a {
    -webkit-animation-delay: .4s;
    animation-delay: .4s
}

.mobile-menu.active .menu li:nth-of-type(4) a {
    -webkit-animation-delay: .5s;
    animation-delay: .5s
}

.mobile-menu.active .menu li:nth-of-type(5) a {
    -webkit-animation-delay: .6s;
    animation-delay: .6s
}
.mobile-menu.active .menu li:nth-of-type(6) a {
    -webkit-animation-delay: .7s;
    animation-delay: .7s
}
.mobile-menu.active .menu li:nth-of-type(7) a {
    -webkit-animation-delay: .8s;
    animation-delay: .8s
}

.mobile-menu .toggle-submenu-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    display: block;
    height: 60px;
    cursor: pointer
}

.mobile-menu .toggle-submenu-button:before {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg)
}

.mobile-menu .toggle-submenu-button:after,
.mobile-menu .toggle-submenu-button:before {
    background: #fff;
    display: block;
    height: 2px;
    margin-top: -2px;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 14px;
    z-index: 1;
    content: ""
}

.mobile-menu .toggle-submenu-button:after {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:before {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg)
}

.mobile-menu .toggle-submenu-button.open-submenu:after {
    transform: rotate(1turn);
    -webkit-transform: rotate(1turn)
}

.mobile-menu .mobile-menu-close span {
    cursor: pointer;
    display: block;
    width: 22px;
    height: 22px;
    position: relative
}

.mobile-menu .mobile-menu-close span:before {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg)
}

.mobile-menu .mobile-menu-close span:after,
.mobile-menu .mobile-menu-close span:before {
    background: #000;
    display: block;
    height: 2px;
    margin-top: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 50%;
    transition: -webkit-transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s;
    transition: transform .25s ease-in-out 0s, -webkit-transform .25s ease-in-out 0s;
    -webkit-transition: -webkit-transform .25s ease-in-out 0s;
    width: 24px;
    z-index: 2;
    content: ""
}

.mobile-menu .mobile-menu-close span:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg)
}

.mobile-menu .mobile-menu-header {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: 15px 0;
    position: fixed;
    top: 0;
    right: 40px;
    left: 40px;
    height: 50px;
    background: #fff;
    z-index: 2;
    text-align: right;
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
}

.mobile-menu .menu a {
    display: block;
    font-size: 17px;
    font-weight: 600;
    color: #000 ;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    font-family: 'Poppins';
    padding: 1.3rem 0;
    line-height: 1;
    text-transform: capitalize;
    letter-spacing: 1px;
}

.mobile-menu .menu a:hover, .mobile-menu .menu li.active a {
    color: #f12148 ;
}

.mobile-menu .menu .sub-menu {
    display: none;
    padding: 0
}

.mobile-menu .menu .sub-menu li {
    padding-right: 0
}

.mobile-menu .menu .sub-menu li:last-child {
    border-bottom: 0
}

.mobile-menu .menu li {
    border-bottom: .0625rem solid rgba(255,255,255,0.22);
    padding-right: 40px
}

@-webkit-keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

@keyframes a {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-80px);
        transform: translateX(-80px)
    }

    to {
        opacity: 1;
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }
}

.mobile-footer-nav{display: none}
.mobile-footer-nav .accordion {
    border: 0;
    border-radius: 0;
}

.mobile-footer-nav .accordion button.title {
    margin: 0;
    border: 0;
    box-shadow: none;
    background: #1d1d1d;
    border-radius: 0!important;
    font-size: 15px;
    color: #ed3650;
}

.mobile-footer-nav .accordion .accordion-item .title:after {
    content: "\f107";
    font-size: 25px;
    color: #ed3650;
    margin-left: 0;
    font-family: "FontAwesome";
    position: absolute;
    right: 20px;
    top: 12px;
    transition: all .3s linear;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

.mobile-footer-nav .accordion .accordion-item.active .title:after{ content: "\f107";transform: rotate(180deg); }

.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #fff;
    border:0;
    border-radius: 0px;
    box-shadow:none;
}

.mobile-footer-nav .accordion .accordion-item.active .panel {
    max-height: 800px;
    transition: max-height .5s linear;
}

.mobile-footer-nav .accordion .accordion-item.active .title {
    background-color: #111;
}

.mobile-footer-nav .accordion .accordion-item .panel {
    background-color: #222;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

.mobile-footer-nav .accordion .accordion-item .panel ul {
    list-style: none;
    padding: 20px;
}

.mobile-footer-nav ul li a {
    color: #fff;
    font-size: 14px;
    display: block;
    font-family: 'poppins';
    font-weight: 500;
    letter-spacing: 0.5px;
     margin-bottom: 5px;
}

.mobile-footer-nav ul li a:hover {
    color: #f12148;
}

.menu-section-mobile-inner .slick-track, .mobile-menu-section-left .slick-track{
    border-bottom: 4px solid #e7e6e6;
}
.menu-section-mobile-inner .slick-track .slick-slide > div, 
.mobile-menu-section-left .slick-track .slick-slide > div{display: block; width: 100%;}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link, 
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link{
    padding: 10px 2px;
    position: relative;
    cursor: pointer;
    color: #000;
    font-family: 'poppins';
    font-weight: 600;
    text-transform: capitalize;
    font-size: 17px;
    line-height: normal;
    display: block;
    height: 100%;text-align: center}

.menu-section-mobile-inner .slick-track .slick-slide > div .menu-title-link:before, 
.mobile-menu-section-left .slick-track .slick-slide > div .menu-title-link:before {
    content: "";
    position: absolute;
    left: 51%;
    right: 51%;
    bottom: -4px;
    background: #f12148;
    height: 4px;
    transition-property: left, right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link:before, 
.mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link:before{ left: 0; right: 0;}
.menu-section-mobile-inner .slick-track .slick-slide > div .active .menu-title-link, 
.mobile-menu-section-left .slick-track .slick-slide > div .active .menu-title-link{color: #181818}
.menu-section-mobile-inner .menu-list-intvl.active .menu-title-link {
    background: #f2e6cf;
    color: #fff !important;
}
.menu-section-mobile-inner, 
.mobile-menu-section-left{display: none}
header.small_sticky {
    box-shadow: 0 2px 16px 0 rgba(0,0,0,.08);
}
.slick-slide .menu-list-intvl, .products_sidebar_inner .slick-slide {
    height: 100%;
}
.products_sidebar_inner .slick-track .slick-slide > div {
    display: block;
    width: 100%;
}

.mobile-menu-section-left button.slick-arrow{
    position: absolute;
    overflow: hidden;
    text-indent: -9999px;
    padding: 0;
    width: 26px;
    height: 26px;
    border-radius: 3px;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.3s linear all;
}

.mobile-menu-section-left .slick-prev{left: 0}

.mobile-menu-section-left .slick-next{right: 0}

.mobile-menu-section-left button.slick-arrow:before{ position: absolute;
    left: 0;
    right: 0;
    width: 14px;
    height: 14px;
    top: 50%;
    margin-top: -8px; background: url(../images/left-arrow.png) no-repeat; content: "";
    margin-left: 6px;}

.mobile-menu-section-left button.slick-arrow.slick-next:before{
    transform: rotate(180deg);
    margin-top: -6px;
}

.hcart_dropdown {
    background: #fff;
    width: 720px;
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    transform: translateX(100%);
    transition: transform .3s ease-in-out;
    z-index: 1000;
    text-align: left;
}

.hcart_dropdown.open {
    transform: translateX(0);
}
body.cart-items-open {
    overflow: hidden !important;
}

.hcart_tt {
    width: 100%;
    background: #0c0c0c;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
}

.hcart_tt h3 {
    color: #fff;
    margin: 0 auto;
    font-size: 18px;
}

span.close_product_orders {
    line-height: 1;
}
header .hcart_dropdown .hcart_scrollarea {
    overflow-y: auto;
    height: calc(100% - 104px);
}

.hcart_dropdown .cart_action {
    padding: 0;
    z-index: 99;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

header .hcart_dropdown .cartaction_bottom .btn{border-radius: 0;}
header .hcart_dropdown .cart_footer .cart_footer_inner_item {width: 100%; padding:20px 30px; background: #f7f7f7;}
.product_card_desc {
    text-align: center;
    padding: 10px;
   /* font-style: italic;*/
}

.spilter {
    width: 2px;
    height: 20px;
    background: #d4d4d4;
    display: inline-block;
    content: "";
    vertical-align: top;
    margin: 1px 2px 0;
    position: relative;
    left: 0;
}

.or-seperator {
    position: relative;
    padding: 15px 0 7px 0;
    line-height: 1;
    margin: 0 auto;
    text-align: center;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 300;
}

.or-seperator span {
    padding: 0 10px;
    background: #fff;
    display: inline-block;
    z-index: 1;
    position: relative
}

.or-seperator:after {
    height: 1px;
    width: 100%;
    content: "";
    display: block;
    background: #0e0e0e;
    position: absolute;
    top: 55%;
    left: 0
}

.welcome-lhs {
    width: 50%;
}
.welcome-rhs {
    width: 50%;
    padding: 80px 0 60px 60px;
}
.welcome-home{position: relative}
.welcome-home .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.welcome-lhs:before{ background: url(../images/welcome-img.jpg) no-repeat center; background-size: cover; position: absolute; left: 0; top: 0; content: ""; height: 100%; width: 50%;  }
.welcome-lhs img{ visibility: hidden;}
.welcome-rhs h4{ margin-bottom:0;font-size: 28px; }
.welcome-rhs h3{ color: #f12148; font-size: 52px;}

.progress_bar_div .progress_bar {
    background: #f12148;
}

.progress_bar_div .progress_bar-free {
    background: #00703c;
}

.progress_bar_div .indication_progress{
    background: #ececec;
}

.progress_bar_div .indication p.help-block{
    margin: 5px 0 0;
    text-align: center;
}

.progress_bar_div .help-block{
    color: #000;
}

.cart_footer .progress_bar_div .indication{
    width: 100%;
}

.page404-main-div img{
    height: 300px;
}

.wh-lhs-top {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.wh-lhs-top h2, .wh-lhs-top p{
    color: #fff;
}
.headertop {
    position: relative;
    background: #161616;
    color: #fff;
}
.headertop .header_sep {
    max-width: 1340px;
    margin: 0 auto;
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.headertop .header_sep a {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: 700;
}
.headertop .header_sep a img {
    margin-right: 15px;
    max-width: 51px;
}
.headertop .header_sep span {
    font-family: 'Poppins';
    font-size: 28px;
    font-weight: 700;
}
.header-brd-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #CCDF58;
    padding: 12px 10px;
}
.header-brd-sec a img {max-width: 90%;}

.header-bottom .header-bottom-innersec, .logo a {
    position: relative;
}

.logo img {
    transition: all .3s linear;
    height: 80px;
}

.hdropdown-list >li, .hdropdown-list >li>a>span {
    position: relative;
}

.hsign_sec.open-submenu{
    position: relative;
}

ul.hmenu_list>li>a {
    font-family: "Poppins";
    font-size: 15px;
    color: #000;
    text-transform: capitalize;
    padding: 20px 10px 18px;
    display: block;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    position: relative;
    margin: 0 3px;
    line-height: 1;
    font-weight: 500;
}

ul.hmenu_list>li>a:after {
    position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    display: block;
    height: 3px;
    width: 0;
    background: #1a8ce7;
    border-radius: 50px;
    -webkit-transition: width .3s ease 0s,left .3s ease 0s;
    transition: width .3s ease 0s,left .3s ease 0s;
}

.hmenu_list>li.active>a:after, ul.hmenu_list>li>a:hover:after {
    width: 100%;
    left: 0;
}

strong, b {
    font-family: 'poppins';
}

.htico_wishlish {
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
}

.htico_cart a.hcartdd_trigger img{
    width: 25px;
    height: 25px;
    display: block;
}

.common-cms-div{
     padding: 45px 0 140px;
}

.hcart_scrollarea .cart_footer .promo-cart-row .cart_remove:after, 
.hcart_scrollarea .cart_footer .promo-cart-row .cart_remove:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 8px;
    width: 1.2px;
    background-color: #fff;
}

.hcart_scrollarea .cart_footer .promo-cart-row .cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hcart_scrollarea .cart_footer .promo-cart-row .cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hcart_scrollarea .cart_footer .cart_remove {
    top: 4px;
    right: 0px;
}

.hcart_scrollarea .cart_footer .promo-cart-row .cart_remove {
    width: 14px;
    height: 14px;
}

ul.hmenu_list>li.active a, ul.hmenu_list>li.active ul li a {
    color: #1a8ce7;
}

.footer-top-inner .location-details {
    width: 32%;
}

.hcart_scrollarea .cart_body .product-details .cart_remove:after, 
.hcart_scrollarea .cart_body .product-details .cart_remove:before {
    position: absolute;
    top: 3px;
    right: 7px;
    content: "";
    height: 10px;
    width: 1.9px;
    background-color: #fff;
}

.hcart_scrollarea .cart_body .product-details .cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.hcart_scrollarea .cart_body .product-details .cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.hcart_scrollarea .cart_body .product-details .cart_remove {
    top: 21px;
}

.form-control{
    -webkit-appearance: none;
   -moz-appearance: none;
    appearance: none;
    background: #fff;
}
input:-webkit-autofill{
    background: #fff !important;
}

/************************************ FOOTER START ***********************************************/

.logo {
    max-width: 82px;
}

.merchant_logo {
    max-width: 120px;
    min-height: 60px;
    display: flex;
    align-items: center;
}

footer { position: relative; background: #000000; padding: 45px 0 0 0; color: #fff;}
.footer-top { padding: 0 0 30px 0; display: flex; justify-content: space-between;}
.footer-newletter { width: 34.03125%;}
.footer-logo { width: 23.90625%;text-align: center;}
.footer-nav { width: 34.6875%; display: flex; flex-wrap: wrap;justify-content: space-between;}
.footer-nav-inner { width: 49%;}
.footer-newletter span{color: #fff; font-size: 12px;display: block;margin: 0 0 5px 0; font-weight: 600}
.footer-newletter h4{ color: #fff; font-size: 20px; text-transform: inherit;line-height: 1.4;}
.f-newletter-form{position: relative}
.f-newletter-form input[type="text"] {
    background: none;
    border: 0;
    border-bottom: 2px solid #a9a9a9;
    padding-left: 2px;
    padding-right: 34px;
    color: #fff;
    border-radius: 0;
}
.f-newletter-form .button {
    position: absolute;
    right: 5px;
    top: 20px;
    margin: 0;
    background: none;
    padding: 0;
    border-radius: 0;
    width: 21px;
    height: 14px;
    font-size: 0;
}
.f-newletter-form .button:after{ background: url(../images/gold-right-arrow-copy.svg) no-repeat; width: 20px; height: 12px; content: "";    display: block; position: absolute; top: 0; left: 0; }
.f-newletter-form input[type="text"]::placeholder {color: #fff;}
.f-newletter-bottom label { font-size: 11px; color: #d6d6d6;line-height: 16px !important;}
.f-newletter-bottom { position: relative;padding-left: 24px;}
.f-newletter-bottom input[type="checkbox"] { position: absolute; left: 0; top: 4px;background: #000;border-color: #ee2442}
.f-newletter-bottom input[type=checkbox]:not(.switch):checked{ background: #f12148; }
.footer-nav h5{ color: #fff;     font-family: 'Poppins', sans-serif; font-size: 14px; font-weight: 700; letter-spacing: 5px;margin-bottom: 20px }
.footer-nav ul{padding: 0; margin: 0; list-style: none;}
.footer-nav ul li a{ color: #fff;display: inline-flex; margin-bottom: 5px; font-weight: 500;}
.footer-nav ul li a:hover{ color: #f12148; }
.social-media a img { height: 28px;width: 33px; }
.social-media a:last-child img{ width: 40px }
.social-media a:first-child{margin-right: 5px;}
.social-media a{display: block; margin-right: 15px}
.social-media {margin: 100px 0 0 0;}
.social-media a:last-child{margin-right: 0}
.social-media img:hover{-webkit-transform: scale(1.1);transform: scale(1.1);
    transition: all .2s cubic-bezier(.63,.05,.43,1.7);}

.copyright-section p {
    font-size: 14px;
    color: #838383;
    text-align: right;
    margin-bottom: 0px;
}

.copyright-section {
    background: #191919;
    padding: 20px 10px;
}

.merchant-footer-top{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 30px;
}

.merchant-footer-logo {
    width: 125px;
}

.merchant-social {
    width: calc(100% - 225px);
    text-align: center;
}

.merchant-social .social-media {
    margin: 0;
}

.merchant-footer-top .footer-newletter {
    order: unset;
}

/********************************* Loyalty system 17-05-22 ************************************/

.myacc_details{
    background-image: url(../images/my_accbanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    padding: 30px 0 20px;
    min-height: 220px;
}

.myacc_Dmain{
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -100px;
}

.myacc_info, .about_info{
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    z-index: 2;
}

.myacc_main_div .merchent_dbord{
    background-image: url(../banner/merchent_dash.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border: 0;
    color: #fff;
}

.about_info{
    position: relative;
    top: 0;
    border: 0;
    z-index: 4;
}

.myacc_info_lhs {
    flex: 1 1 auto;
    max-width: 40%;
    min-width: 250px;
    display: flex;
    justify-content: center;
    flex-flow: column wrap;
    padding: 35px 25px 25px;
}

.welcome-title h1{
    margin-bottom: 15px;
}

.myacc_info_rhs {
    flex: 1 1 auto;
    max-width: 60%;
    min-width: 200px;
    width: 100%;
    background-image: url(../banner/myacc_multibg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.myacc_info_rhs_single{
    justify-content: space-between;
    flex-flow: row;
    background-image: url(../banner/myacc_multibg.jpg);
}

.myacc_rhs_singletier {
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.myacc_info_rhs_single .welcome-title h1, .myacc_info_rhs_multi .welcome-title h1 {
    margin-bottom: 0;
    font-size: 28px;
    text-transform: capitalize;
    color: #fff;
    font-weight: 500;
}

.myacc_info_rhs_single .welcome-title span, .myacc_info_rhs_multi .welcome-title span{
    color: #fff;
}

.myacc_info_rhs_single .myacc_linkpara a, .myacc_info_rhs_multi .myacc_linkpara a{
    color: #fff;
    border-bottom: 1px solid #fff;
}

.myacc_rhs_singletier p, .myacc_rhs_multitier p{
    color: #fff;
    font-size: 12px;
}

.singletier .myacc_linkdesc,  .multitier .myacc_linkdesc{
    font-size: 12px;
}

.singletier .myacc_info_lhs, .multitier .myacc_info_lhs{
    text-align: center;
    padding: 35px;
}

.singletier .myacc_info_lhs .welcome-title, .multitier .myacc_info_lhs .welcome-title{
    text-align: center;
    margin-bottom: 10px;
}

.myacc_rhs_singletier h2{
    color: #fff;
    font-size: 28px;
    font-weight: 500;
}

.multitier_button_rsh{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.multitier_member_div{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.myacc_lhs_multitier {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.myacc_info_rhs_multi .multitier_member_div h3{
    color: #000;
    font-size: 18px;
    padding: 5px 24px;
    border-radius: 50px;
    background: linear-gradient(90deg,#b5b5b3,#ffffff);
    margin-right: 10px;
    margin-bottom: 5px;
}

.myacc_info_rhs_multi li p {
    font-weight: 400;
    margin-bottom: 5px;
}

.merchent_info_rhs{
    background: #fff0;
}

.about_info_lhs{
    padding: 0;
    max-width: 30%;
    z-index: 4;
}

.about_info_rhs{
    max-width: 70%;
    min-width: 200px;
    width: 100%;
    border-radius: 15px;
    padding: 25px;
}

.myacc_linkdesc{
    margin: 0 0 5px;
}

.myacc_info_lhs .welcome-title {
    text-align: left;
}

.myacc_info_lhs h1{
    font-size: 28px;
    text-transform: capitalize;
    font-weight: 500;
}

.welcome-title span{
    font-weight: 200;
    color: #151616;
}

.myacc_info_rhs p{
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
}

.destop_acc_dbutton .button{
    margin: 5px 0 0;
    border-radius: 50px;
    padding: 12px 36px 12px 20px;
    position: relative;
}

.destop_acc_dbutton .button:after, .mobile_acc_dbutton .button:after {
    content: "";
    position: absolute;
    top: 12px;
    right: 15px;
    height: 14px;
    width: 14px;
    background: url(../images/arrow-right.png) no-repeat center;
}

.myacc_info_rhs h2{
    margin: 0;
}

.myacc_linkpara a{
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    font-weight: 500;
    font-size: 13px;
}

.myacc_main_div{
    width: 100%;
}

.myacc_content_main ul{
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.myacc_content_main li{
    list-style: none;
}

.myacc_locat_div{
    display: flex;
    align-items: center;
}

.myacc_locat_div h5, .myacc_locat_div h4, .customrt_product_main h5{
    margin: 0;
    text-transform: inherit;
    font-weight: 700;
}

.myacc_content_main {
    padding: 30px 0 20px;
}

.myacc_content_main li .button{
    color: #fff;
    background: #1a8ce7;
    border-radius: 50px;
    margin: 0;
    padding: 11px 35px 11px;
}

.myacc_content_main li .button:hover{
    background: #000;
}

.myacc_product_main ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.myacc_product_li{
    list-style: none;
    width: 23.828125%;
    margin: 0 1.5625% 20px 0;
    cursor: pointer;
}

.myacc_product_li:nth-child(4n){
    margin-right: 0;
}

.myacc_product_imgdiv {
    position: relative;
}

.inner_img_cotnt {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    width: 100%;
    height: 100%;
}

.myacc_product_imgdiv .inner_img_cotnt{
    padding: 5px;
}

.myacc_product_imgdiv .inner_img_cotnt ul{
    padding: 0;
    justify-content: flex-start;
    height: 100%;
}

.inner_img_cotnt li{
    list-style: none;
    margin-right: 5px;
}

.inner_img_cotnt li.active a, .inner_img_cotnt li:hover a, .inner_img_cotnt li a:hover{
    background: #f12148;
    color: #fff;
}

.myacc_product_imgdiv img, .promo_product_imgdiv img{
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inner_img_cotnt li a{
    background: #e8e8e8;
    padding: 5px 10px;
    border-radius: 15px;
    display: block;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
}

.myacc_product_contntdiv{
    padding: 15px 0 0;
}

.myacc_product_contntdiv ul{
    padding: 0;
    display: block;
}

.myacc_product_contntdiv ul li{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 1;
    padding: 0 0 10px;
}

.myacc_product_contntdiv ul li p{
    margin: 0 10px 10px 0;
}

.myacc_product_contntdiv li img{
    height: 13px;
    margin-right: 8px;
}

.myacc_product_contntdiv h5{
    margin-bottom: 15px;
    text-transform: capitalize;
}

.myacc_product_contntdiv h5:hover{
    color: #f12148;
}

.myacc_product_wish{
    position: absolute;
    top: 5px;
    right: 0;
}

.myacc_product_wish li a{
    height: 25px;
    width: 25px;
    padding: 0;
}

.myacc_product_wish li a img{
    width: 100%;
    height: 100%;
    padding: 5px 4px;
}

.footer-logo_div {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.myacc_Dmain {
    padding-bottom: 80px;
}

.social-media-links{
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-media h6{
    color: #fff;
    text-transform: inherit;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
}

.promotions_info_mem ul{
    padding: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.promotions_info_mem li {
    list-style: none;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    text-align: right;
}

.promotions_info_mem li h3{
    margin-bottom: 0;
    text-transform: capitalize;
    color: #fff;
}

.promotions_info_mem li p{
    color: #fff;
    font-size: 12px;
}

.member-prog-bar{
    display: flex;
    flex-flow: column;
    width: 100%;
}

.member-progress-2 {
    margin:8px auto 8px;
    padding:0;
    width:100%;
    height:4px;
    overflow:hidden;
    background:#151616;
    border-radius:15px;
  }

  .member-bar-2 {
    position:relative;
    float:left;
    min-width:1%;
    height:100%;
    background:#fff;
    border-radius:15px;
  }
  
  .member-percent-2 {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    margin:0;
}
.member-bar-width-2{
    width:55%
}  

.member-help-block{
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
    line-height: 1;
    margin-bottom: 5px;
 }

.member-prog-bar p strong{
    font-weight: 600;
} 

.memb-payment-col-radio .custom_checkbox p{
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    text-transform: uppercase;
}

.promotions_info_mem .destop_acc_dbutton{
    display: flex;
    justify-content: flex-end;
}

.promotions_info_mem{
    align-items: flex-end;
}

.inner_img_cotnt ul{
    justify-content: center;
    align-items: flex-start;
    flex-flow: column;
}

.promotion_content_main li h6{
    margin: 15px 0 0;
    text-transform: capitalize;
    font-size: 15px;
}

.promo_product_li{
    list-style: none;
    width: 32%;
    margin: 0 2% 20px 0;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 8px;
}

.promo_product_li:nth-child(3n), .mercnt-voucher-maindiv .voucher-li:nth-child(3n){
    margin-right: 0;
}

.promo_button .button{
    margin: 0;
    overflow: inherit;
    background: #fff;
    color: #000;
    display: block;
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 12px 26px 12px;
}

.promo_button .button:hover{
    color: #f12148;
}

.promo_product_li .myacc_product_contntdiv, .mercnt-voucher-maindiv .myacc_product_contntdiv{
    padding: 0;
}

.promo_product_imgdiv {
    position: relative;
    height: 94px;
}

.promo_product_li .inner_img_cotnt, .used_voucher .inner_img_cotnt, .mobile_voucher_uddiv .inner_img_cotnt,
.mercnt-voucher-maindiv .voucher-li .inner_img_cotnt, .inner_img_cotnt{
    height: 100%;
    background: #00000059;
    border-radius: 8px;
    padding: 20px;
    align-items: center;
}

.inner_img_lhs {
    width: 49%;
}

.inner_img_rhs {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.inner_img_lhs li {
    color: #fff;
    font-size: 12px;
    margin: 0;
}

.inner_img_lhs li h5, .used_voucher .inner_img_lhs li h4, .mobile_voucher_uddiv .inner_img_lhs li h4,
.voucher-maindiv .inner_img_lhs li h4{
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 0;
}

.inner_img_rhs p{
    font-size: 22px;
    font-weight: 600;
    background: #fff;
    color: #000;
    padding: 6px 20px 5px;
    margin: 0;
    border-radius: 10px;
    line-height: 1;
    text-transform: uppercase;
}

.inner_img_rhs p.vouchar-txt{
    background: transparent;
    color: #fff;
}

.inner_img_rhs p sup{
    font-size: 13px;
    left: 5px;
    bottom: 8px;
}

.voacher_heading h5 {
    margin: 15px 0 20px;
    text-transform: capitalize;
    font-size: 15px;
}

.reward_main ul{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 8px;
    margin-bottom: 15px;
}

.reward_main li{
    list-style: none;
}

.reward_main li img{
    height: 35px;
}

.reward_orderdiv{
    display: flex;
    align-items: center;
}

.reward_orderdiv h5{
    margin: 0 0 0 10px;
}

.reward_datediv p{
    margin: 0;
    color: #c7cacf;
}

.reward_pointsdiv p{
    margin: 0;
    color: #c7cacf;
}

.reward_pointsdiv h5{
    margin: 0;
    color: #1a8ce7;
}

.reward_pointsdiv{
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.myacc_linkpara{
    margin: 0;
}

.homebanner-content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 38%;
}

.homebanner-content-inner{
    width: 420px;
    background: #fff;
    padding: 30px 20px;
    border-radius: 20px;
    box-shadow: 0 0 27px rgb(0 0 0 / 5%);
}

.homebanner-content-inner form{
    display: flex;
    justify-content: space-between;
}

.homebanner-content-inner input.form-control {
    width: 258px;
    margin: 0 10px 0;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    height: 46px;
}

.homebanner-content-inner .react-tel-input .flag-dropdown, .login-rhs-body .react-tel-input .flag-dropdown{
    background: #fff;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
}

.homebanner-content-inner .button{
    margin: 0;
    background: #1a8ce7;
    padding: 15px 30px;
    z-index: 1;
}

.homebanner-content-inner .button:hover{
    background: #000;
}

.homebanner-content h3{
    text-transform: capitalize;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 31px;
}

.homebanner-content h1{
    text-transform: capitalize;
    font-family: 'bessita_handwriting_freeRg';
    font-weight: 500;
}

.login_main_div {
    max-width: 380px;
    margin: 80px 0 120px;
    background: transparent;
    min-height: 300px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.login_form{
    box-shadow: 0 0 27px rgb(0 0 0 / 5%);
    padding: 35px 20px;
    border-radius: 20px;
    background: #fff;
    z-index: 4;
}

.login_form input{
    margin: 0;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    height: 46px;
}

.login-btn .button{
    width: 100%;
    background: #147ee4;
    z-index: 3;
}

.login-btn .button:hover{
    background: #000;
}

.control-group {
    position: relative;
    margin-bottom: 10px;
}

.login_main_div .control-group {
    z-index: unset;
}

.control-group .input-box {
    background: none;
    font-weight: 500;
}

.control-group label {
    position: absolute;
    left: 0;
    width: 100%;
    top: 9px;
    color: #cfcdce;
    transition: all .3s linear;
    z-index: -1;
    padding-top: 0;
}

.focused label, .input-box:focus~label {
    top: -14px;
    font-size: 12px;
    transition: all .3s linear;
    font-weight: 500;
}

.control-flag.focused label, .control-flag:focus~label {
    top: -15px;
    left: 50px;
}

.verification-code {
    text-align: center;
}

.prompt {
    font-weight: 500;
    margin: 0 0 10px;
}

.prompt-box .input-box-small {
    border: 1px solid #c1c3c5;
    border-radius: 10px;
    width: 40px;
    height: 40px;
    padding: 5px;
    font-size:15px;
    text-align: center;
    margin: 0 5px;
    font-weight: 500;
}

.verification-code-div{
    padding-top: 40px;
}

.vefification_div input[placeholder]{
    color: #147ee4;
}

.sucess_imagediv {
    text-align: center;
    margin: 0 0 20px;
}

.logsucess h3, .logsucess h6{
    text-align: center;
    margin: 0 0 5px;
    text-transform: capitalize;
}

.about_tag ul {
    padding: 0;
    justify-content: flex-start;
}

.about_tag li {
    list-style: none;
    margin-right: 5px;
    display: inline-block;
}

.about_tag li a {
    background: #e8e8e8;
    padding: 5px 10px;
    border-radius: 15px;
    display: block;
    line-height: 1;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
}

.about_tag {
    margin: 0 0 10px;
}

.about_desscribtion h4{
    font-weight: 700;
    text-transform: capitalize;
    margin: 0 0 15px;
}

.about_desscribtion ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.about_desscribtion li{
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.about_desscribtion li span{
    border-bottom: 1px solid #868484;
    margin-left: 5px;
}

.about_tag li a:hover{
    color: #db1746;
}

.about_desscribtion li img{
    height: 15px;
    margin-right: 10px;
}

.about_desscribtion p{
    margin: 15px 0;
    font-size: 14px;
    color: #868484;
}

.about-button ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.about-button li{
    list-style: none;
    margin-right: 15px;
}

.about-button li img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.about-button li:first-child a{
    background: #db1746;
}

.about-button li:nth-child(2n) a{
    background: #13a472;
}

.about-button li:nth-child(3n) a{
    background: #282728;
}

.about-button li a{
    display: flex;
    align-items: center;
    margin: 5px 0 0;
}

.about-button li a:hover{
    background: #000;
    color: #fff;
}

.about_info_lhs .myacc_product_imgdiv{
    height: 100%;
}

.about_info_lhs img{
    object-fit: scale-down;
}

.outlet_list{
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.outlet_list ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 10px;
}

.outlet_list_inner{
    width: 49.5%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 25px 20px;
    margin: 0 0 10px;
}

.outlet_list li {
    font-size: 13px;
    display: flex;
    align-items: center;
}

.outlet_list li img {
    height: 15px;
    margin-right: 5px;
}

.outlet_list_inner h5{
    text-transform: capitalize;
    margin-bottom: 15px;
}

.outlet_list_inner li span{
    border-bottom: 1px solid #868484;
    margin-left: 5px;
}

.outlet_address{
    margin: 0;
    font-size: 13px;
    font-weight: 500;
}


/********************************************* OLD CSS START *************************************************/

.mobile-order-nowbtn {
    display: none;
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.12);
}

.home-banner {
    background-image: url(../banner/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    height: 560px;
}

.merchant-banner{
    background-image: url(../banner/merchentbanner.jpg);
    background-position-x: 100% !important;
}

.home-banner .container{
    height: 100%;
}

.home-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.home-banner .slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
}

.home-banner .slick-track .slick-slide > div {
    line-height: 0;
    width: 100%;
    height: 100%;
}

.home-banner .slick-dots{     
    padding: 0;
    margin: 0;
    list-style: none;
    position: absolute;
    bottom: 80px;
    right: 20%;
    text-align: center;}
 .home-banner .slick-dots li button{
    padding: 0;
    margin: 0 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    overflow: hidden;
    text-indent: -9999px;
     background: #f7c55a;
}
.home-banner .slick-dots li{display: inline-block}
.home-banner .slick-dots li.slick-active button{  background: #000;  }
.bann-container{z-index: 3;position: relative;}

.white-home .slick-arrow {
    position: absolute;
    font-size: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #000;
    width: 28px;
    height: 45px;
    top: 50%;
    transform: translateY(-50%);
}
.white-home .slick-arrow.slick-prev {
    background-image: url(../images/home-slider-prev.png);
    left: 0;
    padding: 0;
}
.white-home .slick-arrow.slick-next {
    background-image: url(../images/home-slider-next.png);
    right: 0;
    padding: 0;
}
.white-home .slick-arrow:hover {
    background-color: #18181866;
}

.hordertype_sec{
    position: relative;
}

.hmenu-login-act .hordertype_sec  span.header-arrow{
    position: absolute;
    top: 40%;
    right: 20px;
    cursor: pointer;
}

.hmenu-login-act{
    padding: 0;
}

.hmenu-login-act li{
    list-style: none;
    position: relative;
}

.hmenu-login-act .hservingtype:hover ul{
    display: block;
}

.serving_submenu {
    margin: 0;
    padding: 0px;
    background: #fff;
    z-index: 99;
    width: 220px;
    position: absolute;
    top: 35px;
    display: none;
    text-align: center;
}

.about_info_div ul{
    margin-bottom: 20px;
}

.about_info_div p{
    font-size: 14px;
}

.merchent_dbord h4{
    text-transform: capitalize;
    color: #fff;
    margin: 0 0 10px;
}

.merchent_dbord p{
    color: #fff;
    margin: 0 0 5px;
}

.merchent_dbord .myacc_linkpara{
    margin: 0;
}

.merchent_dbord a{
    color: #fff;
}

.merchent_dbord .welcome-title a{
    margin: 0 0 5px;
    display: block;
}

.merchent_dbord .myacc_linkpara a{
    border-bottom: 1px solid #fff;
    display: inline-block;
}

.merchent_dbord .myacc_linkpara a:hover{
    color: #f12148;
    border-bottom: 1px solid #f12148;
}

.merchent_dbord .welcome-title span{
    color: #fff;
    font-weight: 400;
    margin-left: 20px;
    text-transform: capitalize;
    font-size: 14px;
}

.merchent_title img{
    height: 15px;
    margin-right: 5px;
}

.merchent_dbord .merchent_title a{
    display: flex;
    align-items: center;
}

.merchent_dbord .merchent_title a:hover{
    color: #000;
}

.merchent_info_ul h5{
    margin: 0;
    color: #fff;
    text-transform: capitalize;
}

.merchent_info_ul li p{
    margin: 0;
}

.merchent_info_lhs{
    max-width: 60%;
}

.merchent_info_rhs{
    max-width: 40%;
}

.merchent_info_rhs .member-progress-2{
    height: 4px;
}

.merchent_info_rhs ul li p{
    text-transform: inherit;
}

.merchebt_Rpoint li{
    width: 175px;
    height: 40px;
    background: #ffa485;
    border-radius: 50px;
    box-shadow: 0 0 27px rgb(0 0 0 / 5%);
    position: relative;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    flex-flow: row;
    margin-bottom: 10px;
    cursor: pointer;
}

.merchebt_Rpointul h2{
    width: calc(100% - 40px);
    font-size: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merchebt_Rpointul p{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 40px;
    height: 40px;
    background: #e18465;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.merchebt_Rpointul img{
    height: 25px;
}

.merchebt_Rpoint li.Rpoint_list{
    background: #f1d75c;
}

.merchebt_Rpoint .Rpoint_list p{
    background: #ddb62d;
}

.merchebt_Rpoint ul{
    display: flex;
    flex-flow: column;
    align-items: flex-end;
}

.merchent_lhs{
    width: 100%;
}

.merchent_lhs ul{
    flex-flow: column;
}

/********************************************* COMMON INPUT *****************************************/

.custom_checkbox {
    position: absolute;
    right: 10px;
    display: inline-block;
    line-height: 25px;
    width: 35px;
    height: 35px;
}

.merchent-reward-lhs .custom_checkbox input {
    opacity: 0;
    filter: alpha(opacity=0);
    height: 100%;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    cursor: pointer;
}

.merchent-reward-lhs .custom_checkbox:after{
    display: none;
}

.custom_checkbox span {
    position: relative;
    padding: 0 0 0 25px;
    pointer-events: none;
    color: #3d3d3d;
    display: block;
}

.custom_checkbox span:before {
    content: "";
    background: #fff;
    position: absolute;
    line-height: 35px;
    width: 35px;
    height: 35px;
    top: -1px;
    left: 0;
    pointer-events: none;
    border-radius: 50%;
    border: 1px solid #dde4ec;
    background: url(../images/empty_arrow.png) no-repeat center;
}

.custom_checkbox input[type=checkbox]:checked+span:before {
    content: "";
    text-rendering: auto;
    text-align: center;
    background: url(../images/fill_wite.png) no-repeat center;
    border: 0;
}

.merchent-reward-main {
    padding: 40px 0;
}

.merchent-reward-main h2{
    text-align: center;
    text-transform: capitalize;
    font-weight: 500;
    margin-bottom: 40px;
}

.merchent-reward-inner{
    display: flex;
    justify-content: space-between;
}

.merchent-reward-lhs{
    width: 50%;
    padding: 0 60px 0 60px;
    border-right: 1px solid #efefef;
}

.merchent-reward-rhs{
    width: 50%;
    padding: 0 60px;
}

.merchent-reward-lhs ul{
    padding: 0;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
    list-style: none;
    text-align: center;
}

.merchent-reward-lhs li{
    width: 100%;
    position: relative;
}

.merchent-reward-lhs li input{
    list-style: none;
    border-radius: 50px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 8%);
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    width: 100%;
    margin: 0 0 25px;
    text-align: center;
    position: relative;
    border: none;
    color: #000;
}

.merchent-reward-lhs li sup{
    font-size: 14px;
}

.merchent-reward-lhs p{
    color: #8c9292;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 12px;
}

.merchent-reward-lhs .custom_checkbox{
    top: 50%;
    transform: translateY(-50%);
}

.merchent-reward-rhs .gst-row{
    /*border-bottom: 1px solid #d5dbe4;*/
    padding-bottom: 10px;
    margin-top: 5px;
}

.merchent-reward-rhs .grant-total-cls {
    color: #1a8ce7;
    margin: 0;
    padding-top: 5px;
    border-top: 1px solid #d5dbe4;
}

.merchent-reward-rhs .grant-total-cls p,
.merchent-reward-rhs .grant-total-cls span{
    text-transform: capitalize;
    font-weight: 500;
    font-size: 25px;
}

.merchent-reward-rhs .cart_footer{
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 8%);
}

.merchent-bdiv{
    max-width: 135px;
    width: 100%;
    height: 43px;
    border-radius: 10px;
    padding: 10px;
    justify-content: center;
    border: 1px solid #d5dbe44a;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 8%);
    margin-right: 10px;
    cursor: pointer;
}

.merchent-ul{
    padding: 25px 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.merchent-bdiv .radio_con {
    display: flex;
    align-items: center;
}

.merchent-bdiv:hover, .merchent-bdiv.active{
    border: 1px solid #1a8ce7;
}

.merchent-paybtn .button{
    border-radius: 50px;
    background: #39c27c;
    margin: 0;
    display: block;
}

.merchent-paybtn .button:hover{
    background: #000;
}

.merchent-details {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 3px 30px 0 rgb(0 0 0 / 8%);
    border-radius: 10px;
    padding: 20px;
    margin: 0 0 25px;
}

.amt-pay-para{
    color: #8c9292;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
}

.merchent-details .cart_img {
    width: 45px;
    margin: 0 10px 0 0;
}

.merchent-details .cart_extrainfo p{
    text-transform: capitalize;
    color: #000;
}

.merchent-details .cart_left {
    width: calc(100% - 140px);
    display: flex;
}

.merchent-details .cart_right {
    width: 135px;
    padding-right: 10px;
}

.merchent-details .cart_price p{
    font-size: 14px;
    color: #000;
}

.merchent-details .cart_price {
    position: relative;
    margin: 0 0 10px 0;
    padding-right: 0;
    line-height: 1;
}

.merchent_main_div .login_form {
    box-shadow: none;
    padding: 0;
    border-radius: 0px;
}

.merchent_main_div input{
    box-shadow: 0 0 27px rgb(0 0 0 / 5%);
    border-radius: 50px;
    padding: 5px 20px;
    border: 1px solid #dde4ec4f;
    height: 58px;
    text-align: center;
    font-size: 22px;
    color: #000;
}

.merchent_main_div placeholder{
    font-weight: 600;
    color: #000;
}

.login-field input{
    margin-bottom: 10px;
}
.pasw-filed input{
    margin: 0;
}

.merchent_btn .button{
    border-radius: 50px;
    padding: 18px 26px 18px;
    font-size: 18px;
}

.merchent_main_div h5{
    margin: 0 0 10px;
    text-transform: uppercase;
    color: #8c9292;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

.homebanner-content-inner .special-label, .login-rhs-body .special-label{
    display: none;
}

.form-label {
    position: relative;
    top: -5px;
    font-size: 12px;
    font-weight: 500;
    color: #cfcdce;
}

#page-numbers{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
}

#page-numbers li{
    background: #f1f1f1;
    border: 1px solid #f1f1f1;
    -webkit-transition: background-color .3s, color .3s;
    transition: background-color .3s, color .3s;
    min-width: 40px;
    height: 40px;
    padding: 2px 5px;
    border-radius: 5px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 5px;
    cursor: pointer;
}

#page-numbers li:hover, #page-numbers li.active{
    background: #f12148;
    color: #fff;
}

.mobile_acc_dbutton{
    display: none;
}

.mobile_acc_dbutton .button{
    margin: 0;
    border-radius: 0;
    display: block;
}

.mobile_acc_dbutton .button:after {
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

.mobile_tab_div ul li{
    position: relative;
}

.mobile_tab_div ul li a{
    text-transform: capitalize;
    font-size: 15px;
    padding: 10px 15px;
    font-weight: 500;
}

.mobile_tab_div .mer-mobileul li:before, .customer-voucher-tabul li:before{
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #1a8ce7;
    border-radius: 8px;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: .25s linear;
}

.mobile_tab_div ul>li.active:before, .mobile_tab_div ul>li:hover:before, .customer-voucher-tabul li:hover:before{
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.mer-mobileul li:hover a, .mer-mobileul li.active a, .customer-voucher-tabul li:hover a, .customer-voucher-tabul li.active:before{
    color: #1a8ce7;
}

.mobile_tab_div{
    padding: 30px 0 30px;
}

.customrt_product_main h5{
    margin-bottom: 20px;
}

.mobile_myacc_Dmain{
    display: none;
}

.mobile_tab_div ul{
    justify-content: center;
}

.mobile_tab_div .mobil_promotionul{
    justify-content: center;
}

.promotion_innert_headind{
    padding: 0px 0 20px;
}

.mobile_myacc_Dmain .promotion_innert_headind h6{
    margin-top: 0;
}

.homebanner-content-inner .button.continue_btn {
    padding: 15px 15px;
}

.homebanner-content-inner .custm_password input.form-control{
    width: 245px;
    margin: 0 10px 0 0;
}

.homebanner-content-inner .custm_password{
    margin-bottom: 0;
}

.destop-reward-tab .filter_tabsec{
    box-shadow: 0px 2px 29px rgb(0 0 0 / 10%);
    border-radius: 8px;
}

.destop-reward-ul{
    border: 0;
    margin-bottom: 20px;
    box-shadow: none;
    display: block;
}

.destop-reward-ul li.active{
    width: 50%;
    border: 0;
    border-radius: 25px;
}

.destop-reward-ul li a{
    border: 0;
    padding: 20px;
    border-radius: 10px;
    text-transform: capitalize;
    color: #c7cacf;
    font-weight: 500;
    line-height: 1;
    margin: 0;
}

.destop-reward-ul li{
    width: 50%;
}

.destop-reward-tab .tab-content{
    padding: 0 30px 20px;
}

.destop-reward-ul li.active > a, .destop-reward-ul li.active > a:focus, .destop-reward-ul li.active > a:hover{
    color: #000;
    font-weight: 600;
    border: 0;
}

.destop-reward-ul li a:hover {
    border: 0;
    background-color: #fff;
}

.reward-mbl-para{
    margin-left: 5px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.reward-mbl-para p{
    margin: 0;
    color: #c7cacf;
    font-size: 13px;
    display: none;
}

.reward-mbl-para h5{
    margin: 0;
}

.rward-block .mobile_myacc_Dmain{
    padding-bottom: 0;
}

.login_form .special-label {
    display: none;
}

.login_form input.form-control {
    width: 100%;
    margin: 0 ;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    height: 46px;
}

.login_form .react-tel-input .flag-dropdown {
    background: #fff;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
}

.customer_mainlogin, .customer_Mainregister, .customer_Mlogsucess, .customer_Mvefification{
    background-image: url(../banner/banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    background-position-x: 65%;
}

.register_div .react-datepicker-wrapper{
    width: 100%;
}

.register_div .form-label {
    top: 0px;
}

.merchent_mainlogin, .merchent_adminMdiv{
    background-image: url(../banner/merchentbanner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    position: relative;
    background-position-x: 100%;
}

.pagesList-main-div {
    height: 100%;
}

.merchent_mainlogin:after, .merchent_adminMdiv:after{
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #00000070;
    height: 100%;
    width: 100%;
    z-index: 1;
}

.merchent_adminMdiv:after{
    z-index: -1;
}

.merchent_admin_div{
    background: #f8f8f8;
    box-shadow: 0 0 27px rgb(0 0 0 / 5%);
    border-radius: 20px;
    padding: 30px 20px 20px;
}

.merchent_admin_div h4{
    text-transform: capitalize;
    font-size: 24px;
    text-align: center;
}

.merchent_mainlogin .login_form{
    background: transparent;
}

.merchent_mainlogin .login_form h5{
    color: #fff;
}

.merchent_admin_div .login_form{
    background: transparent;
}

.register_heading_main{
    position: relative;
    display: flex;
    justify-content: center;
}

.register_heading{
    width: calc(100% - 25px);
}

.register_div h4, .register_heading_main .register_heading h4{
    text-transform: inherit;
    text-align: center;
}

.register_div .back-package{
    width: 25px;
}

.register_div .back-package .button{
    background: transparent;
}

.register_div .terms-chk input{
    height: 18px;
    border: 2px solid #cfcdce;
}

.register_div .terms-chk input:focus{
    border: 0;
}

.terms-chk{
    display: flex;
    align-items: center;
    padding: 10px 0 0;
}

.terms-chk a {
    color: #595656;
    border-bottom: 1px solid #595656;
}

.logsucess h6{
    text-transform: inherit;
}

.custom-alrt-popupcls .alert_header{
    background: #fff;
    color: #000;
    font-size: 24px;
}

.custom-alrt-popupcls{
    border-radius: 20px;
}

.custom-alrt-popupcls .alt_btns .button:hover{
    background: #000;
}

.custom-alrt-popupcls .alt_btns .button{
    background: #147ee4;
}

button.mfp-close {
    width: 35px;
    height: 35px;
    line-height: 30px;
    font-size: 0;
    color: #afafaf;
    margin: 0;
    opacity: 1;
    background: #000000c9;
    border: 2px solid #000000;
    right: 5px;
    top: 5px;
    border-radius: 50px;
    min-width: inherit;
}

.mfp-close-btn-in .custom-alrt-popupcls .mfp-close::after, .pay-omisepaynowconf-popup .mfp-close::after, 
.login-popup .mfp-close::after, .tier-benfits-popup .mfp-close::after, .changepw_popup .mfp-close::after,
.voucher_details_popup .mfp-close::after, .promo_details_popup .mfp-close::after, .receipt_popup .mfp-close::after{
    content: "";
    background: url(../images/crosslg.png) no-repeat;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
}

.mfp-close-btn-in .custom-alrt-popupcls .mfp-close:hover, .pay-omisepaynowconf-popup .mfp-close:hover, 
.login-popup .mfp-close:hover, .tier-benfits-popup .mfp-close:hover, .changepw_popup .mfp-close:hover,
.voucher_details_popup .mfp-close:hover, .promo_details_popup .mfp-close:hover, .receipt_popup .mfp-close:hover{
    background: #282828;
}

.mfp-close-btn-in .receipt_popup .mfp-close, .mfp-close-btn-in .receipt_cateringpopup .mfp-close {
    background: rgba(1, 1, 1, 0.9);
}

.mfp-close-btn-in .receipt_popup .mfp-close:hover,.mfp-close-btn-in .receipt_cateringpopup .mfp-close:hover {
    background: rgba(0, 0, 0, 0.9);
}

.pay-omisepaynowconf-popup{
    max-width: 680px;
    border-radius: 20px;
    padding: 40px 20px 50px;
}

.omise-pay-title {
    margin-bottom: 30px;
}

.omise-pay-title h2{
    text-transform: capitalize;
    text-align: center;
}

.omise-pay-img {
    text-align: center;
}

.omise_pay_header {
    margin-bottom: 30px;
}

.omisepop_in .omise-Qrheding{
    text-align: center;
    text-transform: capitalize;
}

.omisepop_inner{
    text-align: center;
}

.Qr_imgdiv{
    background: #fff;
    border: 2px solid #cbcbcb;
    padding: 30px 10px;
    width: 100%;
    text-align: center;
    max-width: 250px;
    margin: 0 auto 30px;
    border-radius: 8px;
    cursor: pointer;
}

.Qr_imgdiv:hover {
    border: 2px solid #f12148;
    background: #fff;
}

.Qr_imgdiv span {
    display: block;
    margin: 20px 0 0;
    text-transform: capitalize;
    letter-spacing: 1;
    color: #000;
    font-size: 15px;
    font-weight: 500;
}

.omise-paynow-amount-to-paid{
    text-align: center;
    margin-bottom: 20px;
}

.paynow-seconds {
    text-align: center;
    display: inline-block;
    background: #efefef;
    border-radius: 50px;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: 500;
}

.paynow-seconds:hover{
    background-image: linear-gradient(to right top, #7257b4, #715cbc, #6f62c3, #6d67cb, #6b6dd2);
}

.paynow-seconds:hover i, .paynow-seconds:hover span{
    color: #fff;
}

.paynow-seconds i{
    margin-right: 3px;
}

.omise-paynow-amount-to-paid span sup{
    font-size: 20px;
}

.paynow-notes{
    margin-top: 20px;
}

.paynow-notes h4{
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 15px;
}

.paynow-notes ul{
    padding: 0;
    list-style: none;
}

.paynow-notes ul li{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-size: 15px;
}

.paynow-notes ul li:last-child{
    margin-bottom: 0;
}

.paynow-notes ul li img{
    width: 12px;
    height: 6px;
    transform: rotate(270deg);
    margin-right: 5px;
}

.aboutmobile_info_lhs, .aboutmobile_info_rhs{
    display: none;
}

.aboutmobile_info_rhs{
    text-align: center;
}

.myacc_product_wish li a.heart_hover{
    display: none;
}

.about_info_lhs .inner_img_cotnt{
    height: 100%;
    padding: 0;
}

.myacc_product_wish li:hover .heart_hover, .myacc_product_wish li.active .heart_hover{
    display: block;
    background: #e8e8e8;
}

.myacc_product_wish li:hover .heart_normal, .myacc_product_wish li.active .heart_normal{
    display: none;
}

.hmenu-login-section a.hcartdd_trigger img {
    width: 25px;
    height: 25px;
    display: block;
    cursor: pointer;
}

.homebanner-content-inner h4{
    text-transform: capitalize;
    font-size: 18px;
}

.merchent-mobile_dbordblock{
    display: none;
}

.merchent-mobile_dbordblock {
    flex: 1 1 auto;
    max-width: 40%;
    min-width: 250px;
    flex-flow: column wrap;
    padding: 20px 20px;
    display: none;
}

.merchent-mobile_outlet{
    display: none;
    background: #000;
}

.merchent-mobile-inbutton {
    display: flex;
}

.merchent-mobile_innername{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px 5px 20px;
}

.merchent-mobile_innername p{
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}

.merchent-mobile-inbutton .button{
    margin: 0;
    border-radius: 50px;
    background: #acb9ca;
    color: #000;
    text-transform: capitalize;
    padding: 6px 18px;
    font-weight: 600;
    font-size: 13px;
    letter-spacing: 0.5px;
}

.merchent-mobile-inbutton .button:hover{
    background: #1a8ce7;
    color: #fff;
}

.merchant-vistmain{
    display: none;
    padding: 10px 10px;
    background: #f8f8f8;
}

.merchant-vistmain p{
    margin: 0;
    font-size: 14px;
    color: #858d8f;
    text-transform: uppercase;
    font-weight: 500;
}

.merchant-voacher-destop{
    padding: 40px 0 30px;
}

.merchant-voacher-destop .nav-tabs{
    border-bottom: 5px solid #eeeeee;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.merchant-voacher-destop .nav-tabs a{
    border-radius: 0;
    color: #000;
    font-weight: 600;
}

.merchant-voacher-destop .nav-tabs>li {
    width: 22%;
    float: left;
    border-bottom: 0;
    position: relative;
}

.merchant-voacher-destop .nav-tabs>li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -4px;
    left: 0;
    background: #1a8ce7;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: .25s linear;
    border-radius: 20px;
}

.merchant-voacher-destop .nav-tabs>li.active:before, .merchant-voacher-destop .nav-tabs>li:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.merchant-voacher-destop .nav-tabs>li.active>a, .merchant-voacher-destop .nav-tabs>li.active>a:focus, 
.merchant-voacher-destop .nav-tabs>li.active>a:hover {
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    color: #1a8ce7;
    font-weight: 600;
}

.voucher-maindiv h6, .mercnt-voucher-maindiv h6{
    text-transform: capitalize;
}

.merchant-voacher-destop .nav-tabs>li > a{
    border: none;
}

.merchant-voacher-destop .nav-tabs>li > a:hover{
    background-color: #fff;
    border: none;
}

.promotion_product_main h6{
    text-transform: capitalize;
}

.merchant-voacher-mobile{
    display: none;
}

.merchant-voucher-tab{
    display: none;
}

.merchent-bdiv:last-child{
    margin-right: 0;
}

.login-popup{
    max-width: 1030px;
    padding: 0;
    border-radius: 15px;
}

.login-lhs{
    width: 50%;
    background: url(../banner/login.jpg) no-repeat;
    background-size: cover;
    background-position: 50%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    position: relative;
}

.login-rhs{
    width: 50%;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    text-align: center;
    padding: 50px 50px;
}

.login-innermain {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 620px;
}

.login-lhs-inner{
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 30px;
    text-align: center;
}

.login-lhs-inner h3{
    color: #fff;
    text-transform: inherit;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
}

.login-lhs-inner p{
    font-size: 15px;
    color: #fff;
    margin: 0;
}

.login-rhs-header h4{
    text-transform: inherit;
    margin-bottom: 15px;
    font-size: 18px;
}

.login-rhs-header p{
    font-size: 15px;
}

.login-rhs-body input{
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    height: 46px;
}

.login-button .button{
    width: 100%;
    background: #1a8ce7;
    color: #fff;
}

.login-button .button:hover{
    background: #000;
    color: #fff;
}

.login-rhs-body p{
    color: #7c7b7b;
}

.singletier_menumain{
    padding: 20px;
    position: relative;
}

.singletier_menumain:before{
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background: #c7cacf;
    height: 1px;
    width: 100%;
    z-index: -1;
}

.singletier_menumain ul{
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.singletier_menumain li:last-child{
    margin-right: 0;
}

.singletier_menumain li{
    width: 20%;
    text-align: center;
    height: 100%;
    margin-right: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    z-index: 4;
    background: #fff;
}

.singletier_menumain li:hover .normal_simg, .singletier_menumain li.active .normal_simg{
    display: none;
}

.singletier_menumain li:hover .hover_simg, .singletier_menumain li.active .hover_simg{
    display: block;
}

.singletier_menumain li:hover a, .singletier_menumain li.active a{
    color: #000;
}

.singletier_menumain a {
    position: relative;
    cursor: pointer;
    color: #c7cacf;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.2;
    height: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.singletier_menumain .hover_simg{
    display: none;
}

.singletier_menumain a img{
    margin-right: 5px;
    width: 35px;
    height: 35px;
}

.myacc_info_rhs_multi, .myacc_main_div .customer_Ndashboard{
    background-image: url(../banner/myacc_multibg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.customer_Ndashboard_rsh{
    display: none;
}

.customer_Ndashboard .myacc_info_lhs{
    align-items: flex-start;
    background: transparent;
}

.customer_Ndashboard .welcome-title h1, .customer_Ndashboard .welcome-title span, .customer_Ndashboard .myacc_linkdesc{
    color: #fff;
}

.customer_Ndashboard .welcome-title h1{
    width: 100%;
}

.customer_Ndashboard .myacc_linkpara a {
    color: #fff;
    border-bottom: 1px solid #fff;
}

.myacc_info_rhs_multi .member-mobile-block{
    margin: 0 0 10px;
    text-align: left;
    font-size: 12px;
    color: #fff;
    line-height: 1;
}

.myacc_info_rhs_multi .multi__rhs_li-left{
    width: 70%;
}

.myacc_info_rhs_multi .multi__rhs_li-right{
    width: 30%;
}

.multi__rhs_li-left .member-help-block{
    text-align: left;
}

.myacc_info_rhs_multi .welcome-title h1{
    text-align: left;
    width: 100%;
}

.aboutbk_multitier, .about_menublock{
    display: none;
}

.about_mobile_infomain{
    display: none;
}

.about-button li:last-child{
    margin-right: 0;
}

.myacc_linkpara a:hover {
    color: #f12148;
    border-bottom: 1px solid #f12148;
}

.merchent_serving {
    padding: 10px 20px;
    display: none;
}

.merchent_serving option{
    background: #fff;
    color: #000;
    font-size: 16px;
}

.merchent_serving .serving_select {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    background: url(../images/navarrow-down.png) no-repeat 100% #1a8ce7;
    background-position: calc(100% - 20px) 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-box-shadow: -0.94px 1.77px 13px rgb(1 1 1 / 10%);
    box-shadow: -0.94px 1.77px 13px rgb(1 1 1 / 10%);
    margin: 0;
    border-radius: 50px;
    height: 56px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border: 0;
}

.myacc_info_rhs_multi .member-mobile-block{
    display: none;
}

.hmenu-login-act{
    display: flex;
    align-items: center;
}

.hservingtype a {
    display: block;
    padding: 8px 42px;
    color: #fff;
    background: #1a8ce7;
    border-radius: 50px;
    border: 2px solid #1a8ce7;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.5px;
    margin: 0 15px 0 25px;
    line-height: 1;
    text-transform: capitalize;
    box-shadow: 0 2px 6px rgb(0 0 0 / 12%);
}

.serving_submenu li .merchant_menu {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 15px;
    color: #1b0000;
    text-transform: capitalize;
    padding: 10px;
    display: block;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078);
    background: #fff;
    position: relative;
    letter-spacing: normal;
    margin: 0;
    line-height: 1;
}

.serving_submenu li .merchant_menu:hover {
    color: #1a8ce7;
    background: #fff;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078);
}

.hservingtype .hordertype_btn:after, .hservingnext a:after{
    content:"\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 20px;
    line-height: 1;
}

.hservingnext a:after{
    transform: rotate(270deg);
}

.hservingnext a{
    margin: 0;
}

.hservingtype a:hover {
    color: #fff;
    background: #1a8ce7;
}

.hservingtype a:hover{
    background: #000;
    color: #fff;
    border: 2px solid #000;
}

.merchant_login-section, span.banner_mbl_error{
    display: none;
}

.singletier_menumain{
    width: 100%;
}

.homebanner-content-inner span.error{
    z-index: 1;
}

.homebanner-content-inner .react-tel-input .flag-dropdown.open .country-list, .login-rhs-body .flag-dropdown.open .country-list{
    z-index: 9;
}

.destop_myacc_Dmain .noprodct-ul, .mobile-noprodct .noprodct-ul{
    max-width: 420px;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
}

.noprodct-imgdiv{
    padding: 40px 0 40px;
}

.noprodct-content{
    max-width: 320px;
    margin: 0 auto;
}

.noprodct-content h5{
    text-transform: inherit;
    margin-bottom: 10px;
}

.noprodct-content p{
    text-transform: inherit;
}

.mobile-noprodct{
    margin: 20px 0 40px;
}

.login_main_div h1{
    text-transform: capitalize;
    font-size: 44px;
    line-height: 1.2;
    margin-bottom: 30px;
}

.login_main_div h1 span{
    font-size: 32px;
    display: block;
}

.login_main_div h6{
    text-transform: inherit;
    font-weight: 500;
}

.terms-chk a:hover{
    color: #da203a;
    border-bottom: 1px solid #da203a;
}

.merchent_admin_div form{
    text-align: center;
}

.merchant-banner .homebanner-content-inner {
    width: 445px;
}

.merchebt_Rpoint p{
    text-align: right;
    font-size: 14px;
}

.merchant-outlet{
    position: relative;
    top: 7px;
}

.merchant-outlet>li {
    display: inline-flex;
    margin-right: 15px;
    position: relative;
}

.merchant-outlet .submenu_list{
    top: 35px;
    right: inherit;
    left: 0;
    text-align: left;
}

.merchant-outlet .htico_sign{
    color: #000;
    font-weight: 500;
    line-height: 1.2;
    flex-flow: column;
    align-items: flex-end;
}

.merchant-outlet .htico_sign span{
    color: #1a8ce7;
    font-size: 13px;
    font-style: italic;
}

.merchant-outlet .submenu-arow{
    top: -5px;
}

.merchant-outlet .submenu-arow:after{
    color: #000;
}
.destop-reward-tab{
    width: 100%;
}

.merchant_tnkyou {
    position: relative;
    margin: 45px 0 110px;
    padding: 2px 0 0;
}

.merchant_header-tick {
    text-align: center;
}

/***** Image black to white color *******/

/*.merchant_header-tick img {
    -webkit-filter: invert(100%) sepia(100%) saturate(0) hue-rotate(288deg) brightness(102%) contrast(102%);
    filter: invert(100%) sepia(100%) saturate(0) hue-rotate(288deg) brightness(102%) contrast(102%);
}*/

.merchant_header-tick h2 {
    font-weight: 600;
    font-size: 32px;
    color: #fff;
    margin: 20px 0 5px;
}

.merchant_header-tick p {
    font-size: 17px;
    margin: 0;
    color: #fff;
}

.thank-order-detaildiv {
    max-width: 580px;
    margin: 0 auto;
    box-shadow: 0 2px 13px 0 rgb(0 0 0 / 10%);
}

.tnk-detail {
    margin-top: 20px;
}

.tnk-detail h2 {
    padding: 13px 10px;
    color: #000;
    text-transform: uppercase;
    background: #fff;
    margin-bottom: 0;
    font-size: 19px;
}

.tnk-order {
    background: #000;
    padding: 30px 15px;
}

.tnk-order h3 {
    margin-bottom: 10px;
    font-size: 36px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
}

.tnk-order p {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 0;
}

.tnk-delivery {
    padding: 30px 15px;
    background: #fff;
}

.merchant-cart-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.merchant-cartlhs {
    width: 100%;
    margin: 0 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.merchant-cartlhs:before {
    position: absolute;
    top: 15px;
    left: 50px;
    right: 30px;
    background: #fff;
    content: "";
    margin: auto;
    border: 1px dashed #dfdad7;
}

.merchant-cartlhs img {
    max-height: 33px;
}

.merchant-cartrhs {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.merchant-cartrhs>div {
    width: 49%;
}

.merchant-cartrhs>div h5 {
    margin-bottom: 5px;
    font-size: 16px;
}

.merchant-cartrhs>div p {
    margin: 0;
    font-size: 15px;
    color: #000;
}

.merchantdate-main {
    position: relative;
    padding: 30px 0;
    margin: 0;
}

.delivery_total_left {
    float: left;
    text-align: left;
    width: 50%;
}

.delivery_total_left h2, .delivery_total_right h2 {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.delivery_total_left h4, .delivery_total_right h4 {
    color: #060203;
    font-size: 15px;
    margin-bottom: 0;
}

.delivery_total_left {
    float: right;
    text-align: right;
    width: 50%;
}

.order-details-with-clear{
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    background: #060203;
    align-items: center;
    line-height: 1;
}

.order-details-with-clear h5{
    color: #fff;
    margin: 0;
    font-size: 15px;
    letter-spacing: .3px;
}

.product-details {
    padding: 10px 0px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
}

.product-details:last-child{
    border-bottom: 0;
    padding-bottom: 0;
}

.cart_img {
    width: 90px;
    margin: 0 15px 5px 0;
}

.cart_left {
    width: calc(100% - 180px);
    display: flex;
    align-items: flex-start;
}


.cart_info {
    display: inline-block;
    vertical-align: top;
    color: #000;
    width: calc(100% - 45px);
}

.cart_info h4 {
    font-size: 14px;
    margin: 0 0 6px;
    color: #000;
    text-transform: inherit;
}

.cart_right {
    width: 175px;
}

.cart_price {
    position: relative;
    margin: 0 0 10px 0;
    line-height: 1;
}

.cart_price p {
    margin: 0;
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.tnk_cart_footer {
    padding: 20px 15px 10px;
    margin: 0;
    margin-top: 15px;
    background: #f8f8f8;
}

.tnk_cart_footer .cart_row {
    display: flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
    padding: 0 0 5px;
    margin-bottom: 5px;
    font-weight: 500;
}

.tnk_cart_footer .cart_row p {
    margin: 0;
}

.tnk_cart_footer span {
    font-size: 16px;
}

.tnk_cart_footer .cart_row.grant-total-cls {
    margin-bottom: 0;
    padding: 10px 0;
    border-top: 1px solid #d5dbe4;
}

.cart_row.grant-total-cls p, .cart_row.grant-total-cls span {
    font-size: 23px;
    line-height: 1.2;
    font-weight: 600;
}

.tnk-chk-order {
    margin: 0;
}

.tnk-chk-order .button {
    display: block;
    margin: 0;
    width: 100%;
    padding: 18px 20px;
    font-size: 17px;
    border-radius: 0;
    background: #000;
    color: #fff;
}

.tnk-chk-order .button:hover {
    background: #f12148;
    color: #fff;
}

.creat_link_main {
    text-align: center;
    margin: 10px 0 0;
    max-width: 258px;
    display: flex;
    justify-content: center;
}

.creat_link_main p, .mobile_creat_link_main p{
    color: #000;
    font-size: 13px;
    font-weight: 500;
    border-bottom: 1px solid #000;
    cursor: pointer;
    margin: 0;
}

.creat_link_main p:hover, .mobile_creat_link_main p:hover{
    color: #1a8ce7;
    border-bottom: 1px solid #1a8ce7;
}

.mobile_creat_link_main{
    display: none;
}

.merchant_innerheading{
    position: relative;
    padding: 20px 20px;
    color: #fff;
    -webkit-box-align: center;
    -ms-flex-align: center;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column;
    min-height: 176px;
}

.merchant_innerheading h3{
    margin: 0;
    color: #fff;
}

.multitier .welcome-title{
    display: flex;
    align-items: center;
    justify-content: center;
}

.myacc_info_lhs .welcome-title .cmpy_logo{
    height: 42px;
    /* width: 95px; */
}

.myacc_info_lhs .cmpy_btn{
    padding: 6px 18px 5px;
    border-radius: 50px;
    margin: 0 auto;
}

.myacc_info_lhs .cmpy_btn a{
    color: #fff;
    font-size: 11px;
    display: block;
}

.hdropdown-list .hmenu-login-act .merchant-outlet ul.merchant_list{
    top: 20px;
}

.order-history-main{
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 45px 45px;
    width: 100%;
    margin: 45px 0;
}

.order-history-header{
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.order-history-header li{
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
}

.order-history-header li a{
    font-weight: 400;
    font-size: 14px;
    position: relative;
    padding: 0 25px 0;
}

.order-history-header li a:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    line-height: 1;
    color: #000;
}

.order-history-header li a:hover:after{
    color: #db1746;
}

.order-details-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 20px 0;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 15px;
}

.order-details-image {
    background: #ffa485;
    border-radius: 15px;
    text-align: center;
    width: 65px;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.order-details-image img{
    width: 30px;
    height: 30px;
}

.order-details-image p {
    font-size: 11px;
    text-transform: capitalize;
    position: absolute;
    bottom: 0;
    margin: 0;
    background: #fcbca3;
    border-radius: 15px;
    padding: 2px 8px;
    font-weight: 500;
}

.order-details-lhs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 50%;
}

.order-details-content {
    padding: 20px 0 20px 20px;
    width: calc(100% - 65px);
}

.order-details-content ul{
    list-style: none;
    padding: 0;
}

.order-title{
    font-weight: 600;
    font-size: 18px;
}

.order-date{
    color: #c7cacf;
    font-size: 13px;
}

.order-name {
    margin: 25px 0 10px;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
}

.order-details-rhs {
    padding: 20px;
    width: 50%;
}

.order-details-right{
    list-style: none;
    padding: 0;
    position: relative;
}

.order-details-right:after{
    position: absolute;
    content: '';
    width: 2px;
    height: 75%;
    bottom: 0;
    right: 11px;
    background: #d3d3d3;
    z-index: -1;
}

.single-order:after{
    height: 60%;
    display: none;
}

.order-details-right .order-title{
    font-weight: 600;
    font-size: 18px;
}

.order-details-right li{
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.order-details-right li span{
    width: 25px;
    height: 25px;
    background: #d3d3d3;
    border-radius: 50%;
    padding: 3px;
    margin-left: 10px;
}

.redeemed-block {
    width: calc(100% - 25px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

li.promo_discount span{
    background: #79e08f;
}

li.order-earned span{
    background: #fea585;
}

li.order-earned{
    margin-bottom: 0;
}

.order-vouchur-image{
    background: #f1c40f;
}

.order-vouchur-image p{
    background: #f9d853;
}

.order-voucher-line:after{
    height: 65%;
}

.voucher-redeemed-line:after{
    height: 50%;
}

li.order-earned-voucher span{
    background: #d3d3d3;
}

.singleorder-name{
    margin: 0;
}

.destop_lhsimage-div {
    height: 100%;
}

.destop_lhsimage-div img{
    border-radius: 20px;
}

.about_info_lhs .button {
    margin: 0;
    border-radius: 8px 8px 0 0;
    text-transform: inherit;
    padding: 18px 24px 22px;
    top: 8px;
    z-index: 1;
}

.merchent_servingul{
    padding: 0;
    list-style: none;
}

.merchent_dropdown a {
    display: block;
    text-align: center;
    padding: 14px 42px;
    color: #fff;
    background: #1a8ce7;
    border-radius: 50px;
    border: 2px solid #1a8ce7;
    font-family: 'Poppins';
    font-size: 15px;
    font-weight: 500;
    position: relative;
    letter-spacing: 0.5px;
    line-height: 1;
    text-transform: capitalize;
}

.merchent_dropdown .hordertype_btn:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    right: 10px;
    top: 12px;
    font-size: 20px;
    line-height: 1;
}

.merchent_dropdown:hover .serving_submenu-inner, .merchent_dropdown.active .serving_submenu-inner{
    display: block;
}

.serving_submenu-inner{
    display: none;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 4;
    width: 100%;
    text-align: center;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.078);
}

.serving_submenu-inner li:last-child a{
    border-bottom: 0;
}

.serving_submenu-inner li .merchant_menu {
    font-family: 'poppins';
    font-weight: 500;
    font-size: 15px;
    color: #1b0000;
    text-transform: capitalize;
    padding: 12px 10px;
    display: block;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.078);
    background: #fff0;
    position: relative;
    letter-spacing: normal;
    margin: 0;
    line-height: 1;
}

.serving_submenu-inner li .merchant_menu:hover {
    color: #1a8ce7;
}

.abt-backbtn{
    margin: 0;
    background: #f12148;
    position: relative;
    top: 28px;
    padding: 10px 25px 30px;
    letter-spacing: normal;
    text-transform: capitalize;
    font-size: 14px;
}

.abt-backbtn:hover{
    background: #000;
}

.abt-backbtn-mobile{ display: none; }

.tier-benfits-popup{
    max-width: 575px;
    padding: 0;
    border-radius: 15px;
}

.tier-benfits-main {
    padding: 40px 30px;
}

.tier-benfits-lhs{
    text-align: center;
}

/***************** MY ACCOUNY ************************/

.box_in {
    background: #fafafa;
}

.account_sec {
    display: flex;
}

.accmenu_sec {
    background: #e8eaec;
    width: 265px;
    display: inline-block;
    position: relative;
    color: #fff;
}

.accprofile {
    padding: 26px 10px 0;
    text-align: center;
}

.accprofile_img {
    width: 140px;
    height: 140px;
    border: 2px solid rgba(0,0,0,.2);
    line-height: 134px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
}

.accprofile_img img {
    vertical-align: middle;
}

.accprofile_info p {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    margin: 0;
}

.setting_menu_list {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.setting_menu_list>ul {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}

.setting_menu_list>ul li {
    position: relative;
}

.setting_menu_list>ul>li>a {
    padding: 12px 10px 12px 20px;
    background: #e8eaec;
    color: #151515;
    display: block;
    text-transform: uppercase;
    border-bottom: 2px solid #fff;
    margin-bottom: 0;
    font-family: "poppins";
    font-weight: 600;
}

.setting_menu_list>ul li a:hover {
    background: #f12148;
    color: #fff;
}

.accsec_right {
    width: calc(100% - 265px);
    width: -webkit-calc(100% - 265px);
    display: inline-block;
    padding: 40px 50px;
}

.accsec_right h1 {
    font-size: 32px;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.accsec_right p {
    font-size: 17px;
}

.profile-info-div {
    margin: 35px 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
}

.acc-inform .form-group {
    margin-bottom: 11px;
}

.acc-inform .col-md-6 {
    width: 49%;
    float: left;
    margin: 0 2% 0 0;
    padding: 0;
}

.acc-inform .col-md-6:last-of-type {
    margin-right: 0;
}

.focus-out {
    position: relative;
}
.focus-out label {
    position: absolute;
    left: 18px;
    top: 14px;
    pointer-events: none;
    transition: all .4s;
    z-index: 3;
    height: 24px;
    overflow: hidden;
    color: #595656;
}

.focused label {
    padding: 0 6px;
    font-size: 11px;
    background: #fff;
    top: -7px;
    height: auto;
}

.css-10nd86i {
    z-index: 4;
}

.gender-inner .css-vj8t7z {
    border-radius: 0;
}

.re_select input[type=text] {
    margin: 0;
    height: 34px;
}

.acc-inform .form-group .row .button {
    width: 100%;
    display: block;
    margin: 0;
    height: 48px;
}

.other-add {
    margin: 30px 0 0 0;
}

.form_grouptt {
    font-size: 16px;
    margin: 0 0 20px 2px;
    color: #000;
    text-transform: capitalize;
}

.other-add-body {
    height: 175px;
    border: 1px solid #f1efef;
    border-radius: 7px;
    padding: 16px 14px 0;
    background: #fff;
}

.other-add-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
}

.other-add-row li {
    width: 32.1875%;
    margin-right: 1.6666666666666665%;
    position: relative;
    margin-bottom: 10px;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li:nth-of-type(3n+3) {
    margin-right: 0;
}

.other-add-row li a {
    background: #f9f9f9;
    padding: 15px;
    display: block;
    border: 1px solid #f1efef;
    color: #252525;
    font-size: 13px;
    border-radius: 7px;
    cursor: default;
}

.mainacc_menucontent {
    margin: 30px 0 100px;
}

.tab_mobtrigger{
    text-align: center;
    font-size: 36px;
}

.user-id {
    color: #000;
}

.changepw_popup {
    max-width: 768px;
    padding: 60px 50px 40px;
    border-radius: 15px;
}

.changepw_popup .btn, .change-pass-sbmt .button {
    background: #f12148;
    width: 100%;
}

.changepw_popup .btn:hover, .change-pass-sbmt .button:hover{
    background: #000;
}

.acc-inform .date-inner{
    padding: 0 5px 0 15px; 
}

.acc-inform .gender-inner{
    padding: 0 15px 0 5px;
}

.acc-inform .unitno_two{
    padding: 0 15px 0 5px;
}

.acc-inform .unitno_one{
    padding: 0 5px 0 15px;
}

/******************** MY ACCOUNT END *********************/

.order-history-inner .load_more_div{
    text-align: center;
}

.destop-reward-tab .load_more_div{
    text-align: center;
}

.mobile_mnu_block{
    width: 100%;
    margin-bottom: 20px;
}

.mobile_mnu_block .slick-track .slick-slide>div{
    width: 100%;
    margin: 15px 7px 5px;
}

.mobile_mnu_block ul{
    list-style: none;
    padding: 0;
}

.mobile_mnu_block .single_block {
    text-align: center;
    height: 100%;
    margin-right: 20px;
    border-radius: 15px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    z-index: 4;
    background: #fff;
}

.mobile_mnu_block a {
    position: relative;
    cursor: pointer;
    color: #c7cacf;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1.2;
    height: 100%;
    padding: 10px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile_mnu_block li:hover a, .mobile_mnu_block li.active a {
    color: #000; 
}

.mobile_mnu_block a img {
    margin-right: 5px;
    width: 35px;
    height: 35px;
}

.mobile_mnu_block .hover_simg {
    display: none;
}

.mobile_mnu_block li:hover .normal_simg, .mobile_mnu_block li.active .normal_simg {
    display: none;
}

.mobile_mnu_block li:hover .hover_simg, .mobile_mnu_block li.active .hover_simg {
    display: block;
}

.mobile_mnu_block .slick-dots {
    display: flex!important;
    margin: 15px 0 0;
    padding: 0;
    background: #d4d4d6;
    height: 6px;
    width: 100%;
    border-radius: 10px;
    list-style: none;
    position: relative;
    justify-content: stretch;
}

.mobile_mnu_block .slick-dots li {
    width: 50%;
}

.mobile_mnu_block .slick-dots li button {
    padding: 0;
    font-size: 0;
    margin: 0;
    background: #cfcecb;
    height: 6px;
    border-radius: 10px;
    width: 100%;
    display: block;
}

.mobile_mnu_block .slick-dots li.slick-active button {
    background: #005fd3;
}

.mobile_mnu_block{
    display: none;
}

.reedem_Indiv{
    position: relative;
}

.reedem_Inbtn{
    position: absolute;
    top: 1px;
    right: 0;
    margin: 0;
    border-radius: 50px;
    padding: 15px 35px 15px;
    background: #1a8ce7;
}

.reedem_Inbtn:hover{
    background: #000;
}

.dash_credit {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    width: 100%;
}

.inner_cotnt_btm{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px;
    width: 100%;
}

.myacc_product_imgdiv .myacc_credit{
    list-style: none;
    padding: 0;
}

.myacc_product_imgdiv .myacc_credit li{
    background: #fff;
    border-radius: 6px;
    padding: 10px;
    display: flex;
    flex-flow: column;
}

.myacc_product_imgdiv .myacc_credit a{
    font-weight: 600;
    color: #147ee4;
}

.myacc_product_imgdiv .myacc_credit span{
    color: #555555;
}

.abt-backbtn-destop{
    display: none;
}

.merchant-footer-main{
    padding: 30px 0 0 0;
}

.merchant-logodiv img{
    height: 120px;
}

/*.home-main-div .merchant-banner {
    height: 100vh;
}*/

.merchent-adminmain{
    height: 100vh;
}

#root, body, html {
    height: 100vh;
}

/********* Merchant New Header *********/

.merchant-allmain{
    background-image: url(../images/member-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px 0;
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
}

.merchant_dash_contain{
    margin-top: 0;
}

.merchant-allul{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 0;
}

.merchant-all-lhs{
    width: 50%;
}

.merchant-all-lhs p{
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
}

.merchant-all-lhs a {
    display: flex;
    align-items: center;
}

.merchant-all-phoe img {
    height: 15px;
    margin-right: 5px;
}

.merchant-all-rhs{
    width: 50%;
    text-align: right;
}

.merchant-all-rhs p{
    margin: 0;
    font-style: italic;
}

.merchant-all-rhs h3 {
    color: #fff;
    font-size: 18px;
    padding: 5px 24px;
    border-radius: 50px;
    background: linear-gradient(90deg,#b4b2b2,#c9cccc);
    margin-bottom: 5px;
    display: inline-flex;
    font-weight: 500;
    box-shadow: 0 2px 6px rgb(0 0 0 / 12%);
}

.merchant-memberinner{
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.merchant-memberinner a{
    display: block;
    width: 32.12154545454545%;
}

.merchant-memberinner a:hover{
    color: #fff;
}

.merchant-memberinner li {
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    margin: 0 1.818181818181818% 0px 0;
    box-shadow: 0 2px 6px rgb(0 0 0 / 12%);
}
.merchant-memberinner li:nth-child(3n) {
    margin-right: 0;
}

.merchant-member-left{
    background: linear-gradient(90deg,#fba383,#f38e6b);
}

.merchant-member-middle{
    background: linear-gradient(90deg,#f3d135,#f5b731);
}

.merchant-member-right{
    background: linear-gradient(90deg,#6ed284,#56bf6d);
}

.merchant-allinner {
    margin-bottom: 8px;
}

.merchant-left-imgdiv{
    background: #f38e6a;
    padding: 10px;
    border-radius: 10px;
    height: 55px;
    width: 55px;
}

.merchant-left-contentdiv h5{
    margin: 0;
    text-transform: capitalize;
    font-weight: 700;
}

.merchant-left-contentdiv p{
    margin: 0;
    font-size: 14px;
}

.merchant-left-contentdiv {
    margin-left: 10px;
}

.merchant-middle-img{
    background: #f1a90f;
}

.merchant-right-img{
    background: #5dc373;
}

.desktop-login-list{
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
}

.hordertype_btn.open-popup-link.htico_sign{
    color: #1a8ce7;
    font-weight: 500;
}

.hordertype_btn.open-popup-link.htico_sign:hover{
    color: #fff;
}


.login-rhs-body input.form-control {
    margin: 0 10px 0;
    border: 0;
    border-bottom: 1px solid #e4e4e4;
    border-radius: 0;
    height: 46px;
}

.login-rhs-body .button{
    z-index: 1;
}

.login-rhs-body .flag-dropdown.open .country-list{
    text-align: left;
}

.verification-code-row input:last-child{
    margin-right: 0;
}

.verification-code-row input:first-child{
    margin-left: 0;
}

.forgot-div {
    width: 325px;
}

.forgot-div .button{
    width: 100%;
    background: #1a8ce7;
}

.forgot-div .button:hover{
    background: #000;
}

.forgt_link, .forg_lbtn .fback_lk{
    font-weight: 500;
    border-bottom: 1px solid transparent;
}

.forgt_link:hover, .forg_lbtn .fback_lk:hover{
    color: #1a8ce7;
    border-bottom: 2px solid #1a8ce7;
}

.time-infodiv, .forgot-info{
    margin: 10px 0;
}

.time-infodiv p{
    font-size: 15px;
    color: #7c7b7b;
}

.countdown span {
    background: #efefef;
    padding: 4px 20px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 13px;
    color: #585858;
}

.verification-prompt p{
    color: #000;
    font-size: 15px;
}

.forgot-info p{
    margin: 0;
    color: #000;
}

.forget-back a{
    font-weight: 500;
    border-bottom: 1px solid transparent;
}

.forget-back a:hover{
    border-bottom: 1px solid #1a8ce7;
    color: #1a8ce7;
}

.register-popdiv .register_heading_main{
    width: 100%;
}

.rpopup-body-main{
    width: 100%;
}

.logsucess-in{
    width: 300px;
}

.voucher_details_popup{
    padding: 0;
    border-radius: 28px;
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
}

.voucher-popup-image img{
    width: 100%;
    height: 100%;
}

.voucher-popup-content {
    padding: 25px 20px 20px;
    text-align: left;
}

.voucher-popup-content h5{
    color: #000;
    font-size: 15px;
    font-weight: 500;
    text-transform: inherit;
    margin-bottom: 20px;
}

.voucher-popup-content h4{
    font-weight: 700;
    text-transform: initial;
    font-size: 22px;
    margin-bottom: 20px;
}

.voucher-popup-content p{
    font-size: 14px;
}

.voucher-popup-button .button{
    margin: 0;
    display: block;
    width: 100%;
    border-radius: 50px;
    padding: 20px 26px;
    background: #1a8ce7;
    color: #fff;
    font-size: 18px;
}

.voucher-popup-button .button:hover{
    background: #000;
}

.voucher-popup-image{
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
    border-radius: 25px;
    overflow: hidden;
}

.V-productpopup{
    text-align: center;
}

.V-productpopup p {
    line-height: 1;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
}

.V-productpopup p.sel-quality{
    font-size: 14px;
    font-weight: 500;
}

.V-productpopup p sup {
    font-size: 16px;
    text-transform: uppercase;
}

.V-productamount{
    margin-bottom: 20px;
}

.merchant-reward-whole{
    width: 100%;
}

.redeem_points{
    position: relative;
}

.rdm_submit{
    position: absolute;
    content: '';
    right: 0;
    top: 1px;
    margin: 0;
    display: block;
    border-radius: 50px;
    padding: 15px 20px;
    background: #1a8ce7;
    color: #fff;
    cursor: pointer;
}

.rdm_submit:hover{
    background: #000;
    color: #fff;
}

.merchent-reward-lhs .redeem_points input{
    padding-right: 105px;
}

.promo_details_popup{
    padding: 0;
    border-radius: 20px;
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
}

.promo-popup-inner{
    padding: 40px 20px;
}

.promo-pop-content h4{
    text-align: center;
}

.merchant-all-phoe {
    display: flex;
    align-items: center;
}

.promotion-common-div{
    padding-bottom: 80px;
}

.mobile-promotion{
    display: none;
}

.myacc_content_main li .promo_button .button {
    color: #000;
    background: #fff;
    border-radius: 8px;
    margin: 0;
    padding: 12px 26px 12px;
}

.myacc_content_main .promo_product_imgdiv img {
    border-radius: 8px;
    height: 100%;
}

.promotion_content_main .promotion-list-ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
}

.merchant-promotion-destop {
    padding: 30px 0 20px;
}

.myacc_locat_div img {
    height: 22px;
    margin-right: 10px;
}

.promotion-merheading{
    padding-left: 0;
    list-style: none;
}

.promotion-merheading .myacc_locat_div{
    margin-bottom: 15px;
}

.promotion_content_main .promotion-merheading h6{
    margin: 0;
}

.promo_product_imgdiv ul{
    padding: 0;
    list-style: none;
}

.promotion_voacher_ul{
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.merchant-promotion-container{
    padding-bottom: 80px;
}

.promotion_content_main .customer-promo-ul{
    display: block;
}

.myacc_product_li .myacc_product_option{
    flex-flow: row;
}

.merchant-tnk-maindiv{
    margin-top: -120px;
}

.merchant-voacher-destop{
    width: 100%;
}

.mobile-menu-close {
    background: #585858;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #000;
}

.mobile-menu-close:hover{
    background: #f12148bd;
    border: 2px solid #f12148;
}

.react-tel-input .selected-flag .arrow {
    border: 0px;
    border-color: #fff;
    border-top: 4px solid #fff !important;
}

.react-tel-input .selected-flag .arrow:after {
    content: "\f107";
    font-family: FontAwesome;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    line-height: 1;
    margin: auto;
    margin-top: -10px;
    transition: 0.3s linear all;
    display: block !important;
    color: #000;
}

.react-tel-input .selected-flag .arrow.up {
    border-top: none;
    border-bottom: 4px solid #fff !important;
}

.promo-pop-content .button {
    display: flex;
    text-align: center;
    justify-content: center;
    min-width: 150px;
    margin: 30px auto 10px auto;
}

.mobile_mnu_block .single_block:hover .normal_simg, .mobile_mnu_block .single_block.active .normal_simg {
    display: none;
}

.mobile_mnu_block .single_block:hover .hover_simg, .mobile_mnu_block .single_block.active .hover_simg {
    display: block;
}

.mobile_mnu_block .single_block:hover a, .mobile_mnu_block .single_block.active a {
    color: #000;
}

.register-logodiv {
    text-align: center;
    margin-bottom: 30px;
}

.register-logodiv img{
    width: 145px;
    height: 64px;
}

.cart_remove:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.cart_remove:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.cart_remove:before, .cart_remove:after {
    position: absolute;
    top: 2px;
    right: 6px;
    content: "";
    height: 8px;
    width: 1px;
    background-color: #fff;
}

.customer_dp_down .submenu_list{
    top: 30px;
}

.footer-logo_div img {
    transition: all .3s linear;
    height: 132px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.reward-list-body .voucher_ul{
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

.reward-list-body .promo-li, .mercnt-voucher-maindiv .voucher-li {
    list-style: none;
    width: 32%;
    margin: 0 2% 20px 0;
    box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
    border-radius: 8px;
}

.promo-li:nth-child(3n) {
    margin-right: 0;
}

.promo-image {
    position: relative;
    height: 230px;
    overflow: hidden;
}

.promo-image img, .voucher_imgdiv img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.promo-earned-info{
    padding: 20px 20px;
    text-align: center;
    
}

.promo-top-div{
    flex: 1 1 auto;
}

.promo-info-div{
    text-align: center;
    padding: 20px 15px;
    height: calc(100% - 230px);
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.receipt_popup {
    padding: 0;
    border-radius: 28px;
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
}

.receipt_popup-image img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
}

.receipt_popup-image {
    box-shadow: 0 0 27px rgb(0 0 0 / 17%);
    border-radius: 25px 25px 0 0;
    overflow: hidden;
}

.mobile_tab_div ul .promo-li a{
    color: #000;
    border-radius: 8px;
    padding: 12px 22px 12px;
    background: #fff;
    font-size: 15px;
    text-transform: capitalize;
    margin: 0;
}

.mobile_tab_div ul .promo-li a:hover{
    color: #f12148;
    background: #fff;
}

.customer-voucher-tab{
    display: none;
}

.cart_img b{
    display: block;
    line-height: 1.1;
}

.tier-dbody-heading p{
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 5px;
}

.tier-dbody-heading h6{
    text-transform: capitalize;
    margin-bottom: 20px;
    font-size: 15px;
}

.reward_main .tier-content-ul{
    box-shadow: none;
    background: #f7f7f7;
    margin-bottom: 10px;
}

.tier-content-ul .reward-mbl-para h5{
    text-transform: capitalize;
    font-size: 14px;
}

.tier-content-para{
    padding: 0 50px;
}

.tier-content-para p{
    margin: 0;
    font-size: 13px;
}

.tier-benfits-header h3{
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
}

.tier-benfits-main .tab-content{
    padding-top: 35px;
}

.tier-benfits-main .nav-tabs {
    border-bottom: 2px solid #e1dfe0;
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tier-benfits-main .nav-tabs>li {
    width: 33.333%;
    float: left;
    border-bottom: 0;
    position: relative;
}

.tier-benfits-main .nav-tabs>li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -2px;
    left: 0;
    background: #272526;
    visibility: hidden;
    transform: scaleX(0);
    transition: .25s linear;
}

.tier-benfits-main .nav-tabs>li.active:before, .tier-benfits-main .nav-tabs>li:hover:before {
    visibility: visible;
    transform: scaleX(1);
}

.tier-benfits-main .nav-tabs>li.active>a, .tier-benfits-main .nav-tabs>li.active>a:focus, .tier-benfits-main .nav-tabs>li.active>a:hover {
    background-color: #fff;
    border: 0;
    border-bottom-color: transparent;
    color: #272526;
    font-weight: 600;
}

.tier-benfits-main .nav-tabs > li > a:hover {
    border-color: transparent;
    background-color: transparent;
    color: #272526;
}

.tier-dbody-content .reward_main li img {
    height: 30px;
}

.promo-popup-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 28px;
}

.promo-earned-inner{
    padding: 30px 20px 20px;
    text-align: left;
}

.tnx-view{
    width: 100%;
}

.tnx-view h4{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tnx-view h4 b{
    width: 45px;
    display: block;
}

.p-name-tnx{
    width: calc(100% - 45px);
}

.promation_btn .voacher_btn-one, .mobile_voucher_uddiv .voacher_btn-one,
.voucher-maindiv .merbtn-redeem{
    margin: 0;
    overflow: inherit;
    background: #fff;
    color: #000;
    display: block;
    text-transform: capitalize;
    letter-spacing: normal;
    padding: 12px 26px 12px;
}

.promation_btn .voacher_btn-one:hover, .mobile_voucher_uddiv .voacher_btn-one:hover,
.voucher-maindiv .merbtn-redeem:hover, .voucher_expiry .promation_btn-one:hover{
    color: #f12148;
}

.mobile_voucher_uddiv .promo-earned-info{
    padding: 0;
}

.voucher-maindiv .promo-image{
    height: 94px;
}

.voucher-maindiv .promo-info-div{
    height: calc(100% - 94px);
    padding: 0;
}

.used_voucher .promo-earned-info {
    padding: 0;
}

.voucher_expiry .promation_btn-one{
    color: #000;
    background: #fff;
    margin: 0;
    padding: 12px 22px 12px;
    display: block;
    text-transform: capitalize;
}

.voucher_imgdiv{
    position: relative;
    height: 94px;
}

.voucher-name{
    font-size: 13px;
}

.voucher-name span{
    padding: 0 5px;
}

.inner_img_lhs li h5, .voucher-maindiv .inner_img_lhs li h4{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    word-break: break-word;
    -webkit-line-clamp: 2;
}